    /* eslint-disable eqeqeq, array-callback-return, no-useless-concat */

    import React, { Component, Fragment } from 'react';
    import Countup from 'react-countup';
    import ReactTable from 'react-table';
    import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Nav, NavItem, NavLink, TabContent, TabPane, Row, Col, Card} from "reactstrap";
    import API_URL from '../../../ApiUrl';
    import axios from 'axios';
    import { connect } from 'react-redux';
    import { ExportCSV } from '../../../services/ExportCSV';
    import "react-datepicker/dist/react-datepicker.css";
    import {matchSorter} from 'match-sorter';
    import Moment from 'moment';
    import Swal from 'sweetalert2';

    
    class RegisterEventSeason extends Component {

        constructor(props){
            super(props)
            this.state = {
                registrasi_data: [],
                event_id:0,
                event_data:[],
                event_selected:'',
                list_consultants: [],
                potensial_detail: "",
                modalSet: "",
                consultant: "",
                register_by_kabkota: [],
                listMembers:[],
                listStudents:[],
                showAction: this.props.dataUser.code_roles === 'RL023' ? '' : this.props.dataUser.code_roles === 'RL001' ? '' : this.props.dataUser.code_roles === 'RL003' ? '' : this.props.dataUser.code_roles === 'RL009' ? '' : 'none',
                currentActiveTab: 0,
                filtered_events: [],
                area: [],
                id_area:''
            }
        }
    
        componentDidMount(){
            this.getDataRegisterEvent()
            this.getDataEvent()
            this.loadConsultant()
            this.getDataRegisterEventByCity();
            this.getDataArea()
        }

        loadConsultant = () => {
            axios.get(API_URL.superStudent + '/master/consultant/list-priotas', {
                headers: {
                    'Authorization': `Bearer ${this.props.dataToken}`
                }
            })
            .then(response => response.data.data)
            .then(result => {
                this.setState({
                    list_consultants: result
                })
            })
            .catch(error => {
                console.log(error)
            })
        }

        getDataRegisterEvent = () => {
            axios.get(API_URL.eventUrl+'load-data/'+this.state.event_id+'/2', {
                headers:{
                    'Authorization': `Bearer ${API_URL.eventToken}`,
                }
            })
            .then(response => response.data.data)
            .then(result => 
                this.setState({ 
                    registrasi_data: result
                }, () => {
                    this.getDataRegisterEventByCity();
                })
            )
            .catch(error => console.log(error))
        }

        getDataRegisterEventByCity = () => {
            axios.get(API_URL.eventUrl+'load-data-kabkota/'+this.state.event_id+'/2', {
                headers:{
                    'Authorization': `Bearer ${API_URL.eventToken}`,
                }
            })
            .then(response => response.data.data)
            .then(result => 
                this.setState({
                    register_by_kabkota: result.event_lokasi
                })
            )
            .catch(error => console.log(error))
        } 

        getDataEvent = () => {
            const url = API_URL.superStudent+'/event/jenis-event/2';
            axios.get(url, { headers: 
                { 'Authorization': `Bearer ${this.props.dataToken}` } }).then(response => response.data)
            .then((data) => {
                this.setState({
                    event_data:data.data
                });
            });
        }

        getDataArea = () => {
            axios.get(API_URL.superStudent+'/master/area', {
                headers: {'Authorization': `Bearer ${this.props.dataToken}`}
            })
            .then(response => response.data.data)
            .then(result => this.setState({area:result}))
            .catch(error => console.log(error))
        }

        getDataRegisterMember = (params) => {
            let formData = new FormData()
            formData.append('id', params.id)
            formData.append('start_date', params.start_date)
            formData.append('end_date', Moment(params.start_date,'YYYY-MM-DD').add('days',7).format('YYYY-MM-DD'))
            axios.post(API_URL.superStudent+'/event/register-members', formData,{
                headers:{ 'Authorization': `Bearer ${this.props.dataToken}`,}
            })
            .then(response => this.setState({listMembers:response.data.data.register}))
            .catch(error => console.log(error))
        }

        getDataRegisterStudent = (params) => {
            let formData = new FormData()
            formData.append('id', params.id)
            formData.append('start_date', params.start_date)
            formData.append('end_date', Moment(params.start_date,'YYYY-MM-DD').add('days',7).format('YYYY-MM-DD'))
            formData.append('code_progress', 'PG004')
            axios.post(API_URL.superStudent+'/event/register-students', formData,{
                headers:{ 'Authorization': `Bearer ${this.props.dataToken}`,}
            })
            .then(response => this.setState({listStudents:response.data.data.register}))
            .catch(error => console.log(error))
        }

        handleChange = (e) => {
            let value = e.target.value
            this.setState({
                [e.target.name]: value
            },() => {
            let filteredData = this.state.event_data.filter(item => item.id == value)
                this.setState({
                    event_selected:filteredData[0]
                })
                this.getDataRegisterEvent()
                this.getDataRegisterMember(filteredData[0])
                this.getDataRegisterStudent(filteredData[0])
            })
        }

        handleChangeSet = (e) => {
            this.setState({
                [e.target.name]: e.target.value
            })
        }

        handlePresent = (row) => {
            let formData = new FormData()
            formData.append('id_event', row.pivot.event_id)
            formData.append('code_register',row.register_code)
            formData.append('status', 2)
            axios.post(API_URL.eventUrl+'world-education-festival/attandance',formData,{
                headers:{ 'Authorization': `Bearer ${API_URL.eventToken}`, }
            })
            .then(() => {
                this.getDataRegisterEvent()
            })
            
        }

        saveData() {
            let formData = new FormData()
            formData.append('id_register_rep', this.state.potensial_detail.register.id)
            formData.append('code_konsultan', this.state.consultant)

            axios.post(API_URL.superStudent+'/event/set-consultant-register', formData, {
                headers: {
                    'Authorization': `Bearer ${this.props.dataToken}`
                }
            })
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500
                })
                this.getDataRegisterEvent()
                this.setState({ modalSet: false })
            })
            .catch(error => {
                console.log(error)
            })
        }

        renderModalSet = () => {
            return (
                <div className="card-body btn-showcase">
                    <Modal isOpen={this.state.modalSet} toggle={() => this.setState({ modalSet: false })}>
                        <ModalHeader toggle={() => this.setState({ modalSet: false })}>Set Handle</ModalHeader>
                        <ModalBody>
                            <div className='form-group'>
                                <select onChange={this.handleChangeSet} name='consultant' className='form-control' value={this.state.consultant}>
                                    <option value=''>Pilih Consultant</option>
                                    {
                                        this.state.list_consultants.map(function (consultan) {
                                            return (<option key={consultan.id} value={consultan.code}>{consultan.name}</option>)
                                        })
                                    }
                                </select>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveData()}>Set</Button>
                            <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.setState({ modalSet: false })}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            )
        }

        setTab(tab) {
            this.setState({ currentActiveTab: tab });
        }

        handleChangeArea = (e) => {
            this.setState({
                [e.target.name]: e.target.value
            });
        };

        
        render(){
            const columns = [
                {
                    Header: 'Nama Lengkap',
                    accessor: 'name',
                    style: {textAlign: 'center'},
                    filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["name"] }),
                    filterAll: true
                },
                {
                    Header: 'Email',
                    accessor: 'email',
                    style: {textAlign: 'center'},
                    filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["email"] }),
                    filterAll: true
                },
                {
                    Header: 'Telepon/WA',
                    accessor: 'phone',
                    style: {textAlign: 'center'},
                    filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["phone"] }),
                    filterAll: true,
                    Cell: (row) => ( 
                        row.original.phone.substring(0,2) == '62' ?
                        <a href={'https://wa.me/'+row.original.phone} rel='noopener noreferrer' target='_blank'>{row.original.phone}</a>
                        :
                        <a href={'https://wa.me/'+'62'+row.original.phone.substring(1)} rel='noopener noreferrer' target='_blank'>{row.original.phone}</a>
                    )
                },
                // {
                //     Header: 'Keterangan',
                //     accessor: 'pivot.keterangan',
                //     style: {textAlign: 'center'},
                //     filterable: false,
                //     Cell: (row) => (
                //         <>
                //             {Moment(row.original.pivot.keterangan).format("DD MMMM YYYY")}
                //         </>
                //     )
                // },
                {
                    Header: 'Kabupaten',
                    accessor: 'kabupaten_name',
                    style: {textAlign: 'center'},
                    filterable: false
                },
                {
                    Header: 'Sekolah',
                    accessor: 'sekolah.nama_sekolah',
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["sekolah.nama_sekolah"]}),
                    style: {textAlign: 'center'},
                    filterAll: true
                },
                {
                    Header: 'Progress',
                    accessor: 'student.progress.name_progress',
                    style: {textAlign: 'center'},
                    filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["student.progress.name_progress"] }),
                    filterAll: true
                },
                {
                    Header: 'Consultant',
                    accessor: 'student.consultant.nama',
                    style: {textAlign: 'center'},
                    filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["student.consultant.nama"] }),
                    filterAll: true
                },
                {
                    Header: 'Attandance',
                    accessor: 'attandance',
                    style: { textAlign: 'center' },
                    Cell: (row) => (
                        row.original.pivot.attandance == null ?
                        <button className='btn btn-success btn-sm btn-successs' onClick={() => this.handlePresent(row.original)}><i className='fa fa-check'></i></button> : 
                        <>
                            {Moment(row.original.pivot.updated_at).format("DD/MM/YYYY")} <br/> {Moment(row.original.pivot.updated_at).format('HH:mm')}
                        </> 
                    ),
                    filterable: false
                }
            ];

            if(this.state.event_selected.status_payment == '1') {
                columns.push(
                    {
                        Header: <b>Status Pembayaran</b>,
                        id: 'delete',
                        accessor: str => "delete",
                        Cell: (row) => (
                            <div>                     
                                {row.original.pivot.status_pembayaran == 'Y' ? <span className='badge badge-success'>Paid</span> : <span className='badge badge-danger'>Not Paid</span>}
                            </div>
                        ),
                        style: {
                            textAlign: 'center'
                        },
                        sortable: false,
                        filterable: false
                    }
                )
            }

            const columns_detail = [
                {
                    Header: 'Nama Lengkap',
                    accessor: 'register.name',
                    style: {textAlign: 'center'},
                    filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["name"] }),
                    filterAll: true
                },
                {
                    Header: 'Email',
                    accessor: 'register.email',
                    style: {textAlign: 'center'},
                    filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["email"] }),
                    filterAll: true
                },
                {
                    Header: 'Telepon/WA',
                    accessor: 'register.phone',
                    style: {textAlign: 'center'},
                    filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["phone"] }),
                    filterAll: true,
                    Cell: (row) => ( 
                        row.original.register.phone.substring(0,2) == '62' ?
                        <a href={'https://wa.me/'+row.original.register.phone} rel='noopener noreferrer' target='_blank'>{row.original.register.phone}</a>
                        :
                        <a href={'https://wa.me/'+'62'+row.original.register.phone.substring(1)} rel='noopener noreferrer' target='_blank'>{row.original.register.phone}</a>
                    )
                },
                {
                    Header: 'Progress',
                    accessor: 'register.student.progress.name_progress',
                    style: {textAlign: 'center'},
                    filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["register.student.progress.name_progress"] }),
                    filterAll: true
                },
                {
                    Header: 'Source',
                    accessor: 'leadsource.source',
                    style: {textAlign: 'center'},
                    filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["leadsource.source"] }),
                    filterAll: true
                },
                {
                    Header: 'Consultant',
                    accessor: 'register.student.consultant.nama',
                    style: {textAlign: 'center'},
                    filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["register.student.consultant.nama"] }),
                    filterAll: true
                },
                {
                    Header: 'Consultant Follow Up',
                    accessor: 'register.consultant.nama',
                    style: { textAlign: 'center' },
                    filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["register.consultant.nama"] }),
                    filterAll: true
                },
            ]

            let attandance = [];
            let member = [];
            let granted = [];
            let ads = [];

            this.state.registrasi_data.map((detail) => {
                if (detail.attandance == 2) {
                    attandance.push(detail);
                }
            })

            this.state.registrasi_data.map((detail) => {
                if (detail.code_student) {
                    if (detail.student.code_member != null) {
                        member.push(detail);
                    }   
                }
            })

            this.state.registrasi_data.map((detail) => {
                if (detail.code_student) {
                    if (detail.student.progress.code_progress == 'PG015') {
                        granted.push(detail);
                    }
                }
            })

            this.state.registrasi_data.map((detail) => {
                if (detail.pivot.code_leads_source == 'LS013') {
                    ads.push(detail);
                }
            })

            const columnArea = [
                {
                Header: 'Name',
                accessor: 'name'
                },
                {
                Header: 'Lokasi',
                accessor: 'area',
                style: { textAlign: 'center' }
                },
                {
                    Header: 'Category',
                    accessor: 'category_event',
                    Cell: row => (
                        <p className={`text-center ${row.value === "Online" ? "text-info" : ""}`}>
                            {row.value === "Online" ? "Online" : "Offline"}
                        </p>
                    )
                },
                {
                Header: 'Date',
                accessor: 'start_date',
                Cell: row => {
                    const date = new Date(row.value);
                    const options = { day:'numeric', month: 'long', year: 'numeric'};
                    return <div className='text-center'>{date.toLocaleDateString('en-GB', options)}</div>
                }
                },
                {
                Header: 'Status',
                accessor: 'status_full',
                Cell: row => (
                    <div>
                        {row.value === 0 ? (
                            <p className="text-success text-center">Open</p>
                        ) : (
                            <p className="text-danger text-center">Closed</p>
                        )}
                    </div>
                )
                },
                // {
                //     Header: 'Info Peserta',
                //     accessor: 'register_data.length',
                //     Cell: () => (
                //         <div className="d-flex justify-content-center">
                //             <button onClick={this.handleChange} className="btn btn-info px-3">Cek</button>
                //         </div>
                //     )
                // },
            ];

            const tab = this.state.currentActiveTab;
            const Activetab = this.state.currentActiveTab;
            const filtered_events = this.state.event_data.filter(item => 
                !this.state.id_area || item.area === this.state.id_area
            );
            return (
                <Fragment>
                    <div className="page-wrapper">
                        <div className="row m-1">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-8">
                                                <h5 className='mb-3'>Lokasi Event</h5>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="text-right">
                                                    <select className="form-control mb-3" onChange={this.handleChangeArea} name="id_area" value={this.state.id_area}>
                                                        <option value="">Pilih Lokasi Event</option>
                                                            {
                                                                this.state.area.map((area)=>{
                                                                    return (<option key={area.id_area} value={area.area}>{area.area}</option>)
                                                                })
                                                            }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <ReactTable
                                            data={filtered_events}
                                            columns={columnArea}
                                            defaultPageSize={5}
                                            filterable
                                            className="-striped -highlight"
                                            showPaginationBottom={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid p-0">
                            <div className="row m-1">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="text-left">
                                                    <h5 className="mb-1">Total Registration : <span className="badge badge-primary"><Countup end={this.state.registrasi_data.length} /></span> </h5>
                                                    </div>
                                                </div>
                                                
                                                <div className="col-sm-6">
                                                    <div className="text-right">
                                                        <select className="form-control" onChange={this.handleChange} name="event_id" value={this.state.event_id}>
                                                            <option value='0'> Data Peserta Event </option>
                                                            {
                                                            this.state.event_data.map(event => {
                                                                return <option value={event.id} key={event.id}>{event.name}</option>
                                                            })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            {
                                this.state.registrasi_data.length > 0 ? 
                                <div>
                                    <div className="row m-1 justify-content-between mr-4">
                                        <div className="col-xl-3 col-sm-3">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="text-center">
                                                        <h5 className="mb-0" >Total Member</h5>
                                                    </div>
                                                    <div className="project-widgets text-center">
                                                        <h1 className="font-primary counter">
                                                            <Countup end={member.length} />
                                                        </h1>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-sm-3">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="text-center">
                                                        <h5 className="mb-0" >Total Attendance</h5>
                                                    </div>
                                                    <div className="project-widgets text-center">
                                                        <h1 className="font-primary counter">
                                                            <Countup end={attandance.length} />
                                                        </h1>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-sm-3">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="text-center">
                                                        <h5 className="mb-0" >Total Granted </h5>
                                                    </div>
                                                    <div className="project-widgets text-center">
                                                        <h1 className="font-primary counter">
                                                            <Countup end={granted.length} />
                                                        </h1>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-sm-3">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="text-center">
                                                        <h5 className="mb-0" >Total Ads</h5>
                                                    </div>
                                                    <div className="project-widgets text-center">
                                                        <h1 className="font-primary counter">
                                                            <Countup end={ads.length} />
                                                        </h1>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                ""
                            }
                            {
                                this.state.register_by_kabkota.length > 0 ?
                                <div className="row m-1">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className='mb-3'>Register By City</h5>
                                                <Nav justified pills className='mt-5 mb-3'>
                                                    {
                                                        this.state.register_by_kabkota.map((kabkota, i) => (
                                                            <NavItem>
                                                                <NavLink className={Activetab === i ? 'active' : ''} onClick={() => this.setTab(i)}>{kabkota.kabupaten.nama_kabkota} - {kabkota.kabupaten.event_register_count}</NavLink>
                                                            </NavItem>
                                                        ))
                                                    }
                                                </Nav>
                                                <TabContent activeTab={tab}>
                                                    {
                                                        this.state.register_by_kabkota.map((kabkota, i) => (
                                                            <TabPane tabId={i}>
                                                                <Row>
                                                                    <div className="row mb-1 ml-5 mt-5">
                                                                        <div style={{display: this.state.showAction}}  className="col-sm-6">
                                                                            <ExportCSV csvData={kabkota.kabupaten.event_register} fileName={"data-registe-event-"+kabkota.kabupaten.nama_kabkota} />
                                                                        </div>
                                                                    </div>
                                                                    <Col sm="12">
                                                                        <Card body>
                                                                            <ReactTable
                                                                                data={kabkota.kabupaten.event_register}
                                                                                columns={columns_detail}
                                                                                defaultPageSize={10}
                                                                                className="-striped -highlight"
                                                                                filterable
                                                                                defaultFilterMethod={(filter, row) =>
                                                                                    String(row[filter.id]) === filter.value}
                                                                            />
                                                                        </Card>
                                                                    </Col>
                                                                </Row>
                                                            </TabPane>
                                                        ))
                                                    }
                                                </TabContent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                ""
                            }
                            {
                                
                            }
                            
                            <div className="row m-1">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className='mb-3'>Register All</h5>
                                            <div className="row mb-3">
                                                <div style={{display: this.state.showAction}}  className="col-sm-6">
                                                    <ExportCSV csvData={this.state.registrasi_data} fileName={"data-registe-event-session"} />
                                                </div>
                                            </div>
                                            <ReactTable
                                                data={this.state.registrasi_data}
                                                columns={columns}
                                                defaultPageSize={10}
                                                filterable
                                                defaultFilterMethod={(filter, row) =>
                                                    String(row[filter.id]) === filter.value}
                                                className="-striped -highlight"
                                                showPaginationBottom={true}
                                                SubComponent={row =>{
                                                    const data = row.original.pivot.keterangan;
                                                    return (
                                                        <div style={{ padding: "20px"}}>
                                                            <div className='row m-b-25'>
                                                                <div className='col-md-12'>
                                                                    <div dangerouslySetInnerHTML={{__html:data}}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <h5>Member</h5>
                                            <ul>
                                                {this.state.listMembers.length > 0 ?
                                                    this.state.listMembers.map(member => (
                                                        <li key={member.id}><i className="fa fa-caret-right txt-secondary m-r-10"></i> ({member.code_member}) {member.name}</li>
                                                    ))
                                                    :
                                                    ''
                                                }
                                            </ul>
                                        </div>
                                    </div>               
                                </div>
                                <div className='col-md-6'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <h5>Student Collect Document</h5>
                                            <ul>
                                                {this.state.listStudents.length > 0 ?
                                                    this.state.listStudents.map(student => (
                                                        <li key={student.code_students}><i className="fa fa-caret-right txt-secondary m-r-10"></i> ({student.code_students}) {student.name}</li>
                                                    ))
                                                    :
                                                    ''
                                                }
                                            </ul>
                                        </div>
                                    </div>        
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.renderModalSet()}
                </Fragment>
            )
        }
    }

    const reduxState = (state) => ({
        dataToken: state.Login.lgnInitialize,
        dataUser: state.Login.detailUser,
        codeUsers: state.Login.detailUser.code_users,
        codeRoles: state.Login.detailUser.code_roles,
    })

    export default connect(reduxState, null) (RegisterEventSeason);