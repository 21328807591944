/* eslint-disable react-hooks/exhaustive-deps */

import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import API_URL from '../../ApiUrl';
import axios from 'axios';
import Moment from 'moment';
import { Nav, NavItem, NavLink, Button, Modal, ModalHeader, ModalBody, ModalFooter, PopoverHeader, PopoverBody, UncontrolledPopover } from 'reactstrap';
import Pusher from 'pusher-js';

const CardTaskList = ({ start_date, end_date }) => {

    const [tasklist, setTaskList] = useState([])
    const [requestlist, setRequestList] = useState([])
    const [detailBy, setDetailBy] = useState('')
    const [message, setMessage] = useState('')
    const [type, setType] = useState('')
    const [detailRow, setRow] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [btnDisabled, setBtnDisable] = useState(false)
    const [statusTask, setStatusTask] = useState('')
    const [description, setDescription] = useState('')

    const { dataToken } = useSelector(state => ({
        dataToken: state.Login.lgnInitialize
    }));

    const { dataUser } = useSelector(state => ({
        dataUser: state.Login.detailUser
    }));

    const [hoveredCard, setHoveredCard] = useState(null);
    const containerRef = useRef(null);

    const styles = {
        container: {
            maxHeight: "400px", // Batas tinggi container
            overflowY: "auto", // Scrollable jika konten melebihi tinggi
            padding: "16px",
            maxWidth: "600px",
            margin: "auto",
            border: "1px solid #ddd",
            borderRadius: "8px",
            backgroundColor: "#f9f9f9",
        },
        card: {
            border: "1px solid #ddd",
            borderRadius: "8px",
            padding: "16px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#fff",
            transition: "background-color 0.3s ease, transform 0.2s ease",
            cursor: "pointer", // Menjadikan pointer saat hover
        },
        cardHover: {
            backgroundColor: "#e3f2fd", // Warna saat hover
            transform: "scale(1.02)", // Sedikit perbesar saat hover
        },
        taskInfo: {
            flex: 1,
            marginRight: "16px",
        },
        title: {
            fontSize: "16px",
            lineHeight:'15px',
            fontWeight: "bold",
            marginBottom: "8px",
        },
        description: {
            fontSize: "14px",
            color: "#555",
        },
        date: {
            fontSize: "14px",
            color: "#888",
        },
        statusContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        priority: {
            fontSize: "14px",
            backgroundColor: "#ffdddd",
            color: "#ff0000",
            padding: "4px 8px",
            borderRadius: "4px",
            marginBottom: "8px",
        },
        toDo: {
            fontSize: "14px",
            backgroundColor: "#e0f7fa",
            color: "#00796b",
            padding: "4px 8px",
            borderRadius: "4px",
        },
    };

    const chatStyle = {
        container: {
            maxWidth: '600px',
            margin: '20px auto',
            padding: '15px',
            border: '1px solid #ddd',
            borderRadius: '8px',
            fontFamily: 'Arial, sans-serif',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            display: 'flex',
            flexDirection: 'column',
            height: '400px', // Fixed height for scrollable area
        },
        header: {
            marginBottom: '15px',
            fontWeight: 'bold',
            fontSize: '18px',
            color: '#333',
            textAlign: 'left',
        },
        messageContainer: {
            flex: 1,
            overflowY: 'scroll',
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
        },
        userMessage: {
            backgroundColor: '#e3f2fd',
            borderRadius: '10px',
            padding: '10px',
            wordBreak: 'break-word',
            alignSelf: 'flex-end',
            marginRight: '10px', // Default margin for sender
        },
        replyMessage: {
            backgroundColor: '#f1f8e9',
            borderRadius: '10px',
            padding: '10px',
            wordBreak: 'break-word',
            alignSelf: 'flex-start',
            marginLeft: '10px', // Default margin for receiver
        },
        inputContainer: {
            display: 'flex',
            gap: '10px',
        },
        input: {
            flex: 1,
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ddd',
        },
        button: {
            padding: '10px 20px',
            backgroundColor: '#2196f3',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
        },
    };

    useEffect(() => {
        loadDataTaskListByConsultant('code_users')
        loadDataTaskListByConsultant('handle_by')
    },[])

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [detailBy]);

    const loadDataTaskListByConsultant = (xtype) => {
        setType(xtype)

        let formData = new FormData()
        formData.append('start_date', start_date)
        formData.append('end_date', end_date)
        formData.append('code_consultant', dataUser.code_konsultan)
        formData.append('code_users', dataUser.code_users)
        formData.append('type', xtype)

        axios.post(API_URL.superStudent + '/todo/list-todo-by', formData, {
            headers: { Authorization: `Bearer ${dataToken}` }
        })
        .then(response => response.data.data)
        .then(result => {
            xtype === 'handle_by' ? setTaskList(result) : setRequestList(result);
        })
        .catch(error => console.log(error))
    }

    const loadDataTaskListDetailByConsultant = () => {
        let formData = new FormData()
        formData.append('id', detailBy.id)

        axios.post(API_URL.superStudent + '/todo/list-todo-detail-by', formData, {
            headers: { Authorization: `Bearer ${dataToken}` }
        })
            .then(response => response.data.data)
            .then(result => {
                setDetailBy(result);
            })
            .catch(error => console.log(error))
    }

    const changeDetailTaskList = (row) => {
        let formData = new FormData()
        formData.append('id', row.id)

        axios.post(API_URL.superStudent + '/todo/update-list-todo-detail-by', formData, {
            headers: { Authorization: `Bearer ${dataToken}` }
        })
        .then(response => response.data.data)
        .then(result => {
            loadDataTaskListByConsultant(type)
            setDetailBy(row);
            setDescription(row.description)
        })
        .catch(error => console.log(error))
    }

    const handleChangeMessage = (e) => {
        setMessage(e.target.value)
    }

    const sendMessage = () => {
        let formData = new FormData()
        formData.append('todo_list_id', detailBy.id)
        formData.append('from', dataUser.code_konsultan)
        formData.append('to', dataUser.code_konsultan === detailBy.handle_by ? detailBy.user.userable.code_konsultan : detailBy.handle_by)
        formData.append('message', message)

        axios.post(API_URL.superStudent + '/todo/update-todo-followup', formData, {
            headers: { Authorization: `Bearer ${dataToken}` }
        })
        .then(response => response.data.data)
        .then(result => {
            setMessage('');
            //callPusher();
            loadDataTaskListDetailByConsultant();
        })
        .catch(error => console.log(error))
    }

    const sendUpdateStatus = () => {
        setBtnDisable(true)
        let formData = new FormData()
        formData.append('id_todo', detailRow.id)
        formData.append('noted', null)
        formData.append('status', statusTask)
        formData.append('attachment', null)

        axios.post(API_URL.superStudent + '/todo/finish-todo', formData, {
            headers: { Authorization: `Bearer ${dataToken}` }
        })
        .then(response => response.data.data)
        .then(result => {
            setBtnDisable(false)
            setShowModal(false)
            setStatusTask('')
            loadDataTaskListByConsultant('code_users')
            loadDataTaskListByConsultant('handle_by')
        })
        .catch(error => console.log(error))
    }

    const editTask = (row) => {
        setRow(row)
        setShowModal(true)
    }

    const modalUpdateStatus = () => {
        return (
            <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
                <ModalHeader toggle={() => setShowModal(false)}>Update Status Task List</ModalHeader>
                <ModalBody>
                    <form className="needs-validation" noValidate="">
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label htmlFor="name">Status Tasklist</label>
                                <select name='status' value={statusTask} className='form-control' onChange={(e) => setStatusTask(e.target.value)}>
                                    <option key={0} value='0'>--Pilih Status--</option>
                                    <option key={1} value='2'>Pending</option>
                                    <option key={4} value='1'>Progress</option>
                                    <option key={2} value='3'>Done</option>
                                    <option key={6} value='5'>Cancel</option>
                                </select>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={btnDisabled} color="primary" onClick={sendUpdateStatus}>Save Changes</Button>
                    <Button disabled={btnDisabled} color="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    useEffect(() => {
        Pusher.logToConsole = false;

        const pusher = new Pusher('7332a6f71d522e88ed0b', {
            cluster: 'ap1',
            encrypted: true,
        });

        const channel = pusher.subscribe('tasklist');
        channel.bind('message', function (data) {
            loadDataTaskListByConsultant('code_users')
            loadDataTaskListByConsultant('handle_by')
        });
        return () => {
            channel.unsubscribe();
            pusher.disconnect();
        };
    }, [])

    return (
        <>
            <div className='card'>
                <div className='card-header'>
                    <h6 className='f-w-600'>Task List</h6>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-lg-5'>
                            <Nav tabs className="border-tab-primary">
                                <NavItem className="nav nav-tabs" id="myTab" role="tablist" style={{ cursor: 'pointer' }} onClick={() => loadDataTaskListByConsultant('handle_by')}>
                                    <NavLink className={type === 'handle_by' ? 'active' : ''}>
                                        <i className="fa fa-list"></i> Task List {tasklist.length}
                                    </NavLink>
                                </NavItem>
                                <NavItem className="nav nav-tabs" id="myTab" role="tablist" style={{ cursor: 'pointer' }} onClick={() => loadDataTaskListByConsultant('code_users')}>
                                    <NavLink className={type === 'code_users' ? 'active' : ''}>
                                        <i className="fa fa-list"></i> Request List {requestlist.length}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <div style={styles.container}>
                                {
                                    type === 'handle_by' ?
                                        tasklist.length > 0 ?
                                            tasklist.map((task) => (
                                                <>
                                                <div
                                                    id='popOverTaskList'
                                                    key={task.id}
                                                    style={{
                                                        ...styles.card,
                                                        ...(hoveredCard === task.id ? styles.cardHover : {}),
                                                    }}
                                                    onMouseEnter={() => setHoveredCard(task.id)} // Aktifkan hover
                                                    onMouseLeave={() => setHoveredCard(null)}
                                                    onClick={() => changeDetailTaskList(task)} // Nonaktifkan hover
                                                >
                                                    <div style={styles.taskInfo}>
                                                        <div style={styles.title}>{task.title}</div>
                                                        <div style={styles.description}>
                                                            {
                                                                task.category != null ?
                                                                    <span>by {task.user.username}, <strong>{task.category != null ? task.category.name : 'general'}</strong></span>
                                                                    :
                                                                    <span></span>
                                                            }
                                                        </div>
                                                        <div style={styles.date}>{Moment(task.created_at).format('DD MMM YY')}</div>
                                                    </div>
                                                    <div style={styles.statusContainer}>
                                                        {task.follow_up_count > 0 && (
                                                            <div style={styles.priority}>{task.follow_up_count}</div>
                                                        )}
                                                        <div className={'badge bg-' + task.status.label}>{task.status.name}</div>
                                                    </div>
                                                </div>
                                                <UncontrolledPopover trigger="click" placement="right" target="popOverTaskList">
                                                    <PopoverHeader>Description</PopoverHeader>
                                                    <PopoverBody><div dangerouslySetInnerHTML={{__html:description}}></div></PopoverBody>
                                                </UncontrolledPopover>
                                                </>
                                            ))
                                            :
                                            'Data Kosong'
                                        :
                                        requestlist.length > 0 ?
                                        requestlist.map((task) => (
                                            <>
                                            <div
                                                id='popOverRequestList'
                                                key={task.id}
                                                style={{
                                                    ...styles.card,
                                                    ...(hoveredCard === task.id ? styles.cardHover : {}),
                                                }}
                                                onMouseEnter={() => setHoveredCard(task.id)} // Aktifkan hover
                                                onMouseLeave={() => setHoveredCard(null)}
                                                onClick={() => changeDetailTaskList(task)} // Nonaktifkan hover
                                            >
                                                <div style={styles.taskInfo}>
                                                    <div style={styles.title}>{task.title}</div>
                                                    <div style={styles.description}>
                                                        {
                                                            task.category != null ?
                                                                <span>to {task.handler.nama}, <strong>{task.category != null ? task.category.name : 'general'}</strong></span>
                                                                :
                                                                <span></span>
                                                        }
                                                    </div>
                                                    <div style={styles.date}>{Moment(task.created_at).format('DD MMM YY')}</div>
                                                </div>
                                                <div style={styles.statusContainer}>
                                                    {task.follow_up_count > 0 && (
                                                        <div style={styles.priority}>{task.follow_up_count}</div>
                                                    )}
                                                    <div className={'badge bg-' + task.status.label}>{task.status.name}</div>
                                                </div>
                                            </div>
                                            <UncontrolledPopover trigger="click" placement="right" target="popOverRequestList">
                                                <PopoverHeader>Description</PopoverHeader>
                                                <PopoverBody><div dangerouslySetInnerHTML={{__html:description}}></div></PopoverBody>
                                            </UncontrolledPopover>
                                            </>
                                        ))
                                        :
                                        'Data Kosong'
                                }
                            </div>
                        </div>
                        <div className='col-lg-7'>
                            {
                                detailBy !== '' ?
                                    <>
                                        <div style={chatStyle.container}>
                                            <div style={chatStyle.header}>
                                                <div className='row'>
                                                    <div className='col-lg-10'>
                                                        {type === 'handle_by' ? <span>By : {detailBy.user.username}</span> : <span>To : {detailBy.handler.nama}</span>}
                                                        <h5>Title : {detailBy.title}</h5>
                                                    </div>
                                                    <div className='col-lg-2'>
                                                        {
                                                            type === 'handle_by' ?
                                                            <button onClick={() => editTask(detailBy)} className="btn btn-xs btn-success" style={{float: 'inline-end'}} type="button"><i className="fa fa-pencil"></i></button>
                                                            :''
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                detailBy.follow_up.length > 0 ?
                                                <div style={chatStyle.messageContainer} ref={containerRef}>
                                                    {
                                                        detailBy.follow_up.map((message, index) => (
                                                            <div
                                                                key={index}
                                                                style={{
                                                                    ...chatStyle[dataUser.code_konsultan === message.from.code_konsultan ? 'userMessage' : 'replyMessage'],
                                                                }}
                                                            >
                                                                {message.message}
                                                                <p style={{ textAlign: 'right', fontSize: '10px' }}>{Moment(message.created_at).format('DD MMM YY hh:mm')}</p>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                :
                                                <div style={chatStyle.messageContainer} ref={containerRef}>
                                                    Data Kosong
                                                </div>
                                            }
                                            <div style={chatStyle.inputContainer} className='mt-5'>
                                                <input style={chatStyle.input}
                                                    type="text" placeholder="Ketik pesan Anda..."
                                                    onChange={(e) => handleChangeMessage(e)}
                                                    value={message}
                                                />
                                                <button style={chatStyle.button} onClick={sendMessage}>Kirim</button>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    'Data Kosong'
                            }
                        </div>
                    </div>
                </div>
                {modalUpdateStatus()}
            </div>
        </>
    )

}

export default CardTaskList;

