/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import API_URL from '../../../ApiUrl';
import ReactTable from 'react-table';
import { matchSorter } from 'match-sorter';
import { formatRupiah } from '../../../helper/format-rupiah';
import { dataScoreIELTS } from '../../../helper/score_ielts';
import { withRouter } from "react-router";
import { toast } from 'react-toastify'


class SimulasiIELTSRegister extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataRegister: [],
            btnDisabled: false,
            textBtn: 'Send Certificate',
            filtered: [],
            filterStatusPayment: ''
        }
    }

    componentDidMount() {
        const { dataUser } = this.props;
        if (dataUser) {
            this.getAllRegisterData()
        }
    }

    getAllRegisterData = () => {
        const { dataUser } = this.props;
        const id_area = dataUser?.id_area;
        const code_roles = dataUser?.code_roles;

        if (!id_area || !code_roles) {
            console.warn('No id_area or code_roles available');
            return;
        }

        axios.get(API_URL.superStudent + '/simulasi-ielts/list-peserta', {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` },
            params: {
                id_area: id_area,
                code_roles: code_roles
            }
        })
            .then(response => this.setState({ dataRegister: response.data.data }))
    }

    SetFilterStatusPayment = (e) => {
        const newData = e.target.value;
        this.setState(prevState => {
            let filterStatusPayment = newData;
            return { filterStatusPayment };
        }, () => {
            this.onFilteredChangeCustom(newData, "status_pembayaran");
        })
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;

        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter["id"] === accessor) {
                    if (value === "" || !value.length) filtered.splice(i, 1);
                    else filter["value"] = value;

                    insertNewFilter = 0;
                }
            });
        }

        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }

        this.setState({ filtered: filtered });
    }

    approvedMember = (row) => {
        let formData = new FormData()
        formData.append('order_id', row.code_order)
        formData.append('transaction_status', 'settlement')
        axios.post(API_URL.superStudent + '/simulasi-ielts/approve-peserta', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then(response => {
                this.getAllRegisterData()
            })
            .catch(error => console.log(error))
    }

    sendSertificated = (row) => {
        this.setState({
            btnDisabled: true,
            textBtn: 'Sending...'
        })
        let formData = new FormData()
        formData.append('token', row.token)
        axios.post(API_URL.superStudent + '/simulasi-ielts/send-certificate', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then(response => {
                this.setState({
                    btnDisabled: false,
                    textBtn: 'Send Certificate'
                })
                toast.success('certificate sent successfully')
            })
            .catch(error => {
                this.setState({
                    btnDisabled: false,
                    textBtn: 'Send Certificate'
                })
                toast.error('there is an error')
            })
    }

    render() {
        const columns = [
            {
                Header: 'Name',
                accessor: 'nama_lengkap',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["nama_lengkap"] }),
                filterAll: true
            },
            {
                Header: 'Email',
                accessor: 'email',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["email"] }),
                filterAll: true
            },
            {
                Header: 'Phone',
                accessor: 'no_wa',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["no_wa"] }),
                filterAll: true,
            },
            {
                Header: 'Status',
                accessor: 'status_pembayaran',
                style: { textAlign: 'center' },
                filterable: true,
                Cell: (row) => (
                    <div>
                        {
                            row.original.status_pembayaran == 'pending' ?
                                <span className={'badge bg-info'}>Pending</span>
                                :
                                row.original.status_pembayaran == 'paid' ?
                                    <span className={'badge bg-success'}>Paid</span>
                                    :
                                    row.original.status_pembayaran == 'expire' ?
                                        <span className={'badge bg-danger'}>Expired</span>
                                        :
                                        <span className={'badge bg-dark'}>Cancel</span>
                        }
                    </div>
                ),
                Filter: () =>
                    <select value={this.state.filterStatusPayment || ''} onChange={(e) => this.SetFilterStatusPayment(e)}>
                        <option value=''>Show All</option>
                        <option value='paid'>Paid</option>
                        <option value='pending'>Peding</option>
                        <option value='expire'>Expired</option>
                    </select>,
            },
            {
                Header: 'Price',
                accessor: 'harga',
                style: { textAlign: 'center' },
                filterable: false,
                Cell: (row) => (
                    formatRupiah(row.original.harga)
                ),
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <>
                        {
                            row.original.is_active === 2 ?
                                <button
                                    onClick={() => this.props.history.push({
                                        pathname: `${process.env.PUBLIC_URL}/pages/english-class/simulasi-ielts/detail/` + row.original.code_order,
                                        state: row.original,
                                    })}
                                    className="btn btn-outline-primary btn-xs" type="button" style={{ border: 'none' }} ><i className="fa fa-eye fa-2x"></i>
                                </button>
                                :
                                row.original.status_pembayaran === 'pending'  ?
                                    <button
                                        onClick={() => this.approvedMember(row.original)}
                                        className="btn btn-outline-secondary btn-xs" type="button" style={{ border: 'none',display: this.props.dataUser.code_roles == 'RL005' ? '' : 'none' }} ><i className="fa fa-check fa-2x"></i>
                                    </button> : ''
                        }
                    </>
                ),
                style: { textAlign: 'center' },
                sortable: false
            },
        ]

        return (
            <>
                <div className="tab-content active default" id="tab-1">
                    <div className="row m-1">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <ReactTable
                                        data={this.state.dataRegister}
                                        columns={columns}
                                        filterable
                                        collapseOnDataChange={false}
                                        filtered={this.state.filtered}
                                        className="-striped -highlight"
                                        showPaginationBottom={true}
                                        showPageSizeOptions={true}
                                        SubComponent={row => {
                                            return (
                                                <div style={{ padding: "20px" }}>
                                                    <div className='row m-b-25'>
                                                        <button disabled={this.state.btnDisabled} className='btn btn-success mb-1' onClick={() => this.sendSertificated(row.original)} >{this.state.textBtn}</button>
                                                        <div className='col-md-12'>
                                                            <table className='table'>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Kategori</th>
                                                                        <th>Nilai</th>
                                                                        <th>Score</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th>Listening</th>
                                                                        <th>{row.original.listening_score}</th>
                                                                        <th>{dataScoreIELTS(row.original.listening_score)}</th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Reading</th>
                                                                        <th>{row.original.reading_score}</th>
                                                                        <th>{dataScoreIELTS(row.original.reading_score)}</th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Writing</th>
                                                                        <th>{row.original.writing_score}</th>
                                                                        <th>{dataScoreIELTS(row.original.writing_score)}</th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Speaking</th>
                                                                        <th>{row.original.speaking_score}</th>
                                                                        <th>{dataScoreIELTS(row.original.speaking_score)}</th>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
})

export default connect(reduxState, null)(withRouter(SimulasiIELTSRegister))