/* eslint-disable eqeqeq */

import React, { Fragment, Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withRouter } from "react-router";
import Loader from '../../components/common/loader';
import 'react-bootstrap-typeahead/css/Typeahead.css';

class BisdevTipe extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            title: "",
            operation: "",
            logName: "",
            errors: {},
            modal: false,
            modalDelete: false,
            btnDisabled: false,
            dataProduk: [],
            id_produk: "",
            name: "",
            description: "",
            prices: [{}],
            target_market: "",
            flier: "",
            flierFile: ""
        }
    }

    componentDidMount() {
        this.getDataBisdevTipe();
    }

    getDataBisdevTipe(){
        const url = API_URL.superStudent+'/bisdev-perform/bisdev-produk';
        axios.get(url,{
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then((data) => {
            this.setState({ dataProduk: data })
        })
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    handleFile = (e, file) => {
        this.setState({
            [e.target.name]:e.target.value,
            [file]:e.target.files[0]
        })
    }

    addClick(){
        this.setState(prevState => ({ 
            prices: [...prevState.prices, {}]
        }))
    }

    removeClick(i){
        let prices = [...this.state.prices];
        prices.splice(i, 1);
        this.setState({ prices });
    }

    handleChangeRow(i, e) {
        let prices = [...this.state.prices];
        prices[i] = {...prices[i], [e.target.name]:e.target.value};
        this.setState({ prices });
    }

    addRow() {
        this.setState({
            modal: true,
            title: 'Add Name Produk Data',
            operation: 'add',
            logName: 'ADD-Name-Produk',
            id_produk: "",
            name: "",
            description: "",
            prices: [{}],
            target_market: "",
            flier: "",
            flierFile: ""
        });
    }

    editRow(row) {
        axios.get(API_URL.superStudent+'/bisdev-perform/bisdev-produk/'+row.id_bisdev_produk, {
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => {
            this.setState({
                modal: true,
                title: 'Edit Bisdev Tipe Data',
                operation: 'edit',
                logName: 'EDIT-BISDEV_TIPE',
                id_produk: result.id_bisdev_produk,
                name: result.nama_bisdev_produk,
                description: result.description,
                prices: result.price != null ? JSON.parse(result.price) : [{}],
                target_market: result.target_market,
                flier: "",
                flierFile: ""
            })
        })
    }

    deleteRow(row) {
        this.setState({
            id_produk: row.id_bisdev_produk,
            modalDelete: true,
            title: 'Delete Bisdev-Produk Data',
            operation: 'delete',
            logName: 'DELETE-Bisdev-Produk'
        })
    }

    sendData() {
        this.setState({
            btnDisabled: true
        });

        var formData = new FormData();
        formData.append('name',this.state.name);
        formData.append('description',this.state.description);
        formData.append('price', JSON.stringify(this.state.prices));
        formData.append('target_market',this.state.target_market);
        formData.append('flier', this.state.flier.replace(/^C:\\fakepath\\/, "").replace(/\.[^/.]+$/, ""));
        formData.append('flier_file',this.state.flierFile);

        formData.append('user',this.props.dataId);
        formData.append('token',this.props.dataToken);

        let url = '/bisdev-perform/bisdev-produk';

        if (this.state.operation == 'edit') {
            formData.append('_method', 'PATCH');
            url = '/bisdev-perform/bisdev-produk/'+this.state.id_produk
        } else if (this.state.operation == 'delete') {
            formData.append('_method', 'DELETE');
            url = '/bisdev-perform/bisdev-produk/'+this.state.id_produk
        }

        axios.post(API_URL.superStudent+url,formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`, 
                'Content-Type': 'multipart/form-data' 
            }
        }).then((reply)=>{
            if (reply.data.status == 'Success') {
                this.closeDialog();
                this.getDataBisdevTipe();
                this.setState({ btnDisabled: false });
                if (this.state.operation == "add") {
                    toast.success("Save Sucsessfully");
                } else {
                    toast.success("Update Sucsessfully");
                }
            } else {
                toast.error(reply.data.message);
            }
        }).catch(err => {
            if(err.response.status === 422 || err.response.status === 409){
                this.setState({
                    errors: err.response.data.data,
                    btnDisabled: false
                })
            }
        });
    }

    closeDialog() {
        this.setState({
            modal: false,
            modalDelete: false,
            city: "",
            country: "",
            harga: "",
            itenary: "",
            min_pax: "",
        });
    }

    renderDialog() {
        const { title, modal } = this.state;

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>{title}</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Nama Bisdev Produk</label>
                                    <input className="form-control" name="name" type="text" id="name" onChange={(e) => this.handleInput(e)} value={this.state.name} />
                                    <span>{this.state.errors.name}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Description</label>
                                    <textarea className="form-control" name="description" id="description" onChange={(e) => this.handleInput(e)} value={this.state.description}></textarea>
                                    <span>{this.state.errors.description}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <div className='row'>
                                        <div className='col-md-1'><label htmlFor="name">Price</label></div>
                                        <div className='col-md-1'><button className="btn btn-success btn-xs pull-left" type="button" onClick={this.addClick.bind(this)}><i className="fa fa-plus"></i></button></div>
                                    </div>
                                    {
                                        this.state.prices.map((harga, i) => (
                                            <div className='row'>
                                                <div className='col-md-11'>
                                                    <input className="form-control" name="harga" type="text" id="harga" onChange={this.handleChangeRow.bind(this, i)} value={harga.harga} />
                                                </div>
                                                <div className='col-md-1'>
                                                    {
                                                        i != 0 ? 
                                                        <button className="btn btn-danger btn-xs pull-right" type="button" onClick={this.removeClick.bind(this, i)}><i className="fa fa-trash"></i></button>
                                                        : ""
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                    <span>{this.state.errors.price}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Target Market</label>
                                    <input className="form-control" name="target_market" type="text" id="target_market" onChange={(e) => this.handleInput(e)} value={this.state.target_market} />
                                    <span>{this.state.errors.target_market}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Flier</label>
                                    <input className="form-control" name="flier" type="file" id="flier" onChange={(e) => this.handleFile(e, 'flierFile')} value={this.state.flier} />
                                    <span>{this.state.errors.flier}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.sendData()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    renderDeleteDialog() {
        const { title, modalDelete } = this.state;

        return (
            <Modal isOpen={modalDelete} toggle={() => this.setState({ modalDelete: false })}>
                <ModalHeader toggle={() => this.setState({ modalDelete: false })}>{title}</ModalHeader>
                <ModalBody>
                    Delete this data ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.sendData()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    formatRupiah = (nominal) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
        }).format(nominal);
    }

    render() {

        const columns = [
            {
                Header: 'Kode Produk',
                accessor: 'kode_bisdev_produk',
                filterable: false
            },
            {
                Header: 'Nama Produk',
                accessor: 'nama_bisdev_produk',
                filterable: false
            },
            {
                Header: 'Description',
                accessor: 'description',
                filterable: false
            },
            {
                Header: 'Price',
                accessor: 'price',
                filterable: false,
                Cell: (row) => (
                    <div>
                        {
                            row.original.price != null ?
                            row.original.price.map(item => this.formatRupiah(item.harga)).join(",")
                            :''
                        }
                    </div>
                )
            },
            {
                Header: 'Target Market',
                accessor: 'target_market',
                filterable: false
            },
            {
                Header: 'Flier',
                Cell: (row) => (
                    <div>
                        {
                            row.original.flier != null ?
                            <img alt='flier' style={{width: '100px'}} src={row.original.flier}/>
                            : ""
                        }
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: true,
                filterable: false
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div>                     
                        <button onClick={() => this.editRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i>{row.id}</button>
                        <button onClick={() => this.deleteRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></button>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ]

        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <button onClick={() => this.addRow()} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} >
                                            <div>
                                            <span>
                                                <i className="fa fa-plus" style={{ width: 35, fontSize: 20, padding: 8, color: 'rgb(40, 167, 69)' }}></i>
                                            </span>
                                            </div>
                                        </button>
                                        <br/>
                                        {
                                            this.state.dataProduk.length > 0 ?
                                            <ReactTable
                                                data={this.state.dataProduk}
                                                columns={columns}
                                                filterable
                                                defaultFilterMethod={(filter, row) =>
                                                    String(row[filter.id]) === filter.value}
                                                defaultPageSize={50}
                                                className="-striped -highlight"
                                            /> 
                                            : 
                                            <Loader showLoading={this.state.showLoading} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderDialog()}
                {this.renderDeleteDialog()}
            </Fragment>
        )
    }

}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState)(withRouter(BisdevTipe));