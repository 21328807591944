/* eslint-disable eqeqeq */

import React, { Component } from "react"
import { ArrowUp, ArrowDown } from 'react-feather';
import { Progress } from 'reactstrap';
import CountUp from 'react-countup';
import Slider from 'react-slick';
import { color3, color4 } from "../../../../constant/variable";


class DetailKPI extends Component {

    constructor(props){
        super(props)
        this.state = {

        }
    }

    formatRupiah = (nominal) => {
        return new Intl.NumberFormat('id-ID', {}).format(nominal);
    }

    render(){
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll:1,
            arrows: true,
            autoplay:true,
            responsive: [
            {
                breakpoint: 1280,
                settings: {
                slidesToShow: 2,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                slidesToShow: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                slidesToShow: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                slidesToShow: 1,
                }
            },
            {
                breakpoint: 370,
                settings: {
                slidesToShow: 1,
                }
            }
            ]
        };

        return(
            <>
                <div className="p-4 card widget-arrow">
                    <Slider {...settings} >
                    {
                    this.props.indikator.length > 0 ? 
                    this.props.indikator.map((item, index) => (
                    <>
                        <div className="col-sm-12 pr-3">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="top-server">
                                        <h6 className="mb-4 font-weight-bold">{item.indikator}</h6>
                                    </div>
                                </div>
                                <div className="col-sm-6 text-right">
                                {
                                item.value > item.last_value ?
                                    <h5 className="mb-0"><ArrowUp className="font-success font-weight-bold" />
                                        <span className="counter font-success f-14">
                                            <CountUp className="counter font-success font-weight-bold" end={item.last_value == 0 ? 100 : Math.abs(item.value/item.last_value*100 )} />%
                                        </span>
                                    </h5>
                                    :
                                    <h5 className="mb-0"><ArrowDown className="font-danger font-weight-bold" />
                                        <span className="counter font-danger f-14">
                                            <CountUp className="counter font-danger font-weight-bold" end={Math.abs((item.value-item.last_value)/item.last_value*100 ) || 0 } />%
                                        </span>
                                    </h5>                            
                                }                    
                                    <div style={{fontSize:'10px',color:'grey'}}>{item.indikator === 
                                                            'Income' || item.indikator === 'Inc Member' ? this.formatRupiah(item.value-item.last_value) : item.value - item.last_value } 
                                    </div>
                                </div>
                            </div>
                            <div className="bottom-server">
                                <h6 style={{color:color3}} className="mb-1 font-weight-bold">{item.indikator === 'Income' || item.indikator === 'Inc Member' ? this.formatRupiah(item.value): item.value}<span style={{color:'black'}}>/</span><span style={{color:color4}}className="font-danger">{item.indikator === 'Income' || item.indikator === 'Inc Member' ? this.formatRupiah(item.target_value): item.target_value}</span></h6>
                            </div>
                            <Progress multi style={{ height: "15px"}}>
                                <Progress animated style={{backgroundColor:color3}} bar value={item.value/item.target_value*100 }></Progress>
                                    <Progress animated bar style={{backgroundColor:'#d6d6c2'}} value={item.value > item.target_value ? 0 : (item.target_value-item.value)/item.target_value*100 } />
                            </Progress>
                        </div>
                    </>
                    ))
                    :<></>
                }
                </Slider>
                            </div>
            </>
        )
    }
}

export default DetailKPI