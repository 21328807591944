/* eslint-disable eqeqeq, no-useless-concat */

import React, { Component, Fragment } from 'react';
import Countup from 'react-countup';
import ReactTable from 'react-table';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import {matchSorter} from 'match-sorter';
import Moment from 'moment';

class RegisterEventWorkshop extends Component {

    constructor(props) {
        super(props)
        this.state = {
            id_topics:'',
            event_id:'',
            topics_data:[],
            event_data:[],
            registrasi_data:[]
        }
    }

    getDataTopik = () => {
        const url = API_URL.superStudent+'/event/list-topiks';
        axios.get(url, { 
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` } 
        })
        .then(response => response.data)
        .then((data) => {
            this.setState({
                topics_data:data.data
            });
        });
    }

    getEventByTopik = () => {
        let formData = new FormData()
        formData.append('id_topiks', this.state.id_topics)

        const url = API_URL.superStudent+'/event/event-by-topiks';
        axios.post(url, formData, { 
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` } 
        })
        .then(response => response.data)
        .then((data) => {
            this.setState({
                event_data:data.data
            });
        });
    }

    getDataRegisterEvent = () => {
        axios.get(API_URL.eventUrl+'load-data/'+this.state.event_id+'/5', {
            headers:{
                'Authorization': `Bearer ${API_URL.eventToken}`,
            }
        })
        .then(response => response.data.data)
        .then(result => 
            this.setState({ 
                registrasi_data: result
            })
        )
        .catch(error => console.log(error))
    }

    handlePresent = (row) => {
        let formData = new FormData()
        formData.append('id_event', row.pivot.event_id)
        formData.append('code_register',row.register_code)
        formData.append('status', 2)
        axios.post(API_URL.eventUrl+'world-education-festival/attandance',formData,{
            headers:{ 'Authorization': `Bearer ${API_URL.eventToken}`, }
        })
        .then(() => {
            this.getDataRegisterEvent()
        })
        
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]:e.targe.value
        })
    }

    handleChangeTopics = (e) => {
        this.setState({
            id_topics:e.target.value
        },() => {
            this.getEventByTopik();
        })
    }

    handleChangeEvents = (e) => {
        this.setState({
            event_id:e.target.value
        },() => {
            this.getDataRegisterEvent();
        })
    }

    componentDidMount() {
        this.getDataTopik();
    }
    
    render(){
        const columns = [
            {
                Header: 'Nama Lengkap',
                accessor: 'name',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["name"] }),
                filterAll: true
            },
            {
                Header: 'Email',
                accessor: 'email',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["email"] }),
                filterAll: true
            },
            {
                Header: 'Telepon/WA',
                accessor: 'phone',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["phone"] }),
                filterAll: true,
                Cell: (row) => ( 
                    row.original.phone.substring(0,2) == '62' ?
                    <a href={'https://wa.me/'+row.original.phone} rel='noopener noreferrer' target='_blank'>{row.original.phone}</a>
                    :
                    <a href={'https://wa.me/'+'62'+row.original.phone.substring(1)} rel='noopener noreferrer' target='_blank'>{row.original.phone}</a>
                )
            },
            {
                Header: 'Kabupaten',
                accessor: 'kabupaten_name',
                style: {textAlign: 'center'},
                filterable: false
            },
            {
                Header: 'Sekolah',
                accessor: 'sekolah.nama_sekolah',
                style: {textAlign: 'center'},
                filterable: false
            },
            {
                Header: 'Progress',
                accessor: 'student.progress.name_progress',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["student.progress.name_progress"] }),
                filterAll: true
            },
            {
                Header: 'Consultant',
                accessor: 'student.consultant.nama',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["student.consultant.nama"] }),
                filterAll: true
            },
            {
                Header: 'Attandance',
                accessor: 'attandance',
                style: { textAlign: 'center' },
                Cell: (row) => (
                    row.original.pivot.attandance == null ?
                    <button className='btn btn-success btn-sm btn-successs' onClick={() => this.handlePresent(row.original)}><i className='fa fa-check'></i></button> : 
                    <>
                        {Moment(row.original.pivot.updated_at).format("DD/MM/YYYY")} <br/> {Moment(row.original.pivot.updated_at).format('HH:mm')}
                    </> 
                ),
                filterable: false
            }
        ];
        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row m-1">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className='col-sm-4'>
                                                <label>Topics</label>
                                                <select className="form-control mb-3" onChange={this.handleChangeTopics} name="id_topics" value={this.state.id_topics}>
                                                    <option value="">--Choose Topics--</option>
                                                    {
                                                        this.state.topics_data.map((topics)=>{
                                                            return (<option key={topics.id_topiks} value={topics.id_topiks}>{topics.name_topics}</option>)
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className='col-sm-4'>
                                                <label>Event Topics</label>
                                                <select className="form-control mb-3" onChange={this.handleChangeEvents} name="event_id" value={this.state.event_id}>
                                                    <option value="">--Choose Events--</option>
                                                    {
                                                        this.state.event_data.map((events)=>{
                                                            return (<option key={events.id} value={events.id}>{events.name}</option>)
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row m-1">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className='mb-3'>Register All <span className="badge badge-primary"><Countup end={this.state.registrasi_data.length} /></span></h5>
                                        <ReactTable
                                            data={this.state.registrasi_data}
                                            columns={columns}
                                            defaultPageSize={10}
                                            filterable
                                            defaultFilterMethod={(filter, row) =>
                                                String(row[filter.id]) === filter.value}
                                            className="-striped -highlight"
                                            showPaginationBottom={true}
                                            SubComponent={row =>{
                                                const data = row.original.pivot.keterangan;
                                                return (
                                                    <div style={{ padding: "20px"}}>
                                                        <div className='row m-b-25'>
                                                            <div className='col-md-12'>
                                                                <div dangerouslySetInnerHTML={{__html:data}}></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState, null) (RegisterEventWorkshop);