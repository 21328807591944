import React, { Component, Fragment } from "react";
import Countup from "react-countup";
import ReactTable from "react-table";
import API_URL from "../../../ApiUrl";
import axios from "axios";
import { connect } from "react-redux";
import { ExportCSV } from "../../../services/ExportCSV";
import "react-datepicker/dist/react-datepicker.css";
import { matchSorter } from "match-sorter";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Logo from '../../../assets/images/logo_prioritas.png';
import Swal from "sweetalert2";
import Moment from 'moment';

class RegisterEventPartner extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      registrasi_data: [],
      event_id: 0,
      event_data: [],
      selectedCategory: "",
      keterangan: "",
      attendance: "",
      modalScan: false,
      code_register: "",
    };
  }

  componentDidMount() {
    this.getDataEvent();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.modalScan && this.state.modalScan) {
      if (this.inputRef.current) {
        this.inputRef.current.focus();
      }
    }
  }

  getDataRegisterEvent = () => {
    axios
      .get(API_URL.eventUrl + "load-data/" + this.state.event_id + "/4", {
        headers: {
          Authorization: `Bearer ${API_URL.eventToken}`,
        },
      })
      .then((response) => response.data.data)
      .then((result) => {
        const formattedData = result.map((data) => {
          let keterangan = {};
          try {
            keterangan = JSON.parse(data.pivot.keterangan);
          } catch (e) {
            console.error("Invalid JSON in keterangan", e);
            keterangan = data.pivot.keterangan;
          }
          return {
            ...data,
            keterangan: keterangan,
          };
        });
        this.setState({ registrasi_data: formattedData });
      })
      .catch((error) => console.log(error));
  };

  getDataEvent = () => {
    const url = API_URL.superStudent + "/event/jenis-event/4";
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${this.props.dataToken}` },
      })
      .then((response) => response.data)
      .then((data) => {
        this.setState({
          event_data: data.data,
        });
      });
  };

  handleChange = (e) => {
    const newEventId = e.target.value;
    if (newEventId !== this.state.event_id) {
      this.setState(
        {
          event_id: newEventId,
        },
        () => {
          this.getDataRegisterEvent();
        }
      );
    }
  };

  handleChangeText = (e) => {
    this.setState({
      [e.target.name]:e.target.value
    })
  }

  toggleModal = () => {
    this.setState({ modalScan: !this.state.modalScan, code_register: '' });
  };

  saveAttandance = (e) => {
    e.preventDefault()

    let formData = new FormData()
    formData.append('id_event', this.state.event_id)
    formData.append('code_register', this.state.code_register)
    formData.append('status', 2)

    axios.post(API_URL.eventUrl+'world-education-festival/attandance', formData, {
      headers: { 
        'Authorization': `Bearer ${API_URL.eventToken}`, 
        'Content-Type': 'multipart/form-data' 
      }
    })
    .then((response) => {
      this.setState({ 
        code_register: '' 
      },() => {
        this.getDataRegisterEvent();
      })
      response.data.message === 'Attandance Member' ?
      Swal.fire({
        imageUrl: Logo,
        imageWidth: 450,
        imageHeight: 150,
        imageAlt: "Logo Prioritas Member",
        title: "Registrasi Berhasil"
      })
      :
      Swal.fire({
        title: 'Registrasi Berhasil',
        icon: 'success',
        type: 'success',
      })
    })
    .catch((error) => {
      console.log(error)
    })
  }

  handlePresent = (row) => {
    let formData = new FormData()
    formData.append('id_event', row.pivot.event_id)
    formData.append('code_register',row.register_code)
    formData.append('status', 2)
    axios.post(API_URL.eventUrl+'world-education-festival/attandance',formData,{
      headers:{ 'Authorization': `Bearer ${API_URL.eventToken}`, }
    })
    .then((response) => {
      this.setState({ 
        code_register: '' 
      },() => {
        this.getDataRegisterEvent();
      })
      response.data.message === 'Attandance Member' ?
      Swal.fire({
        imageUrl: Logo,
        imageWidth: 450,
        imageHeight: 150,
        imageAlt: "Logo Prioritas Member",
        title: "Registrasi Berhasil"
      })
      :
      Swal.fire({
        title: 'Registrasi Berhasil',
        icon: 'success',
        type: 'success',
      })
    })
  }

  renderScanAttandanceDialog() {
    const { modalScan } = this.state;
    return (
      <div className="card-body btn-showcase">
          <Modal isOpen={modalScan} toggle={() => this.setState({ modalScan: false })}>
            <ModalHeader toggle={() => this.setState({ modalScan: false })}>Scan Attandance</ModalHeader>
              <ModalBody>
                <form onSubmit={this.saveAttandance} className="needs-validation" noValidate="">
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="name">Code Register</label>
                      <input className="form-control" name="code_register" ref={this.inputRef} type="text" onChange={this.handleChangeText} value={this.state.code_register} />
                    </div>
                  </div>
                  <button type='button' className='btn btn-secondary btn-air-info mt-4 ml-4 pull-right' onClick={this.toggleModal}>Cancel</button>
                  <button type='submit' className='btn btn-primary btn-air-info mt-4 ml-4 pull-right'>Checkin</button>
                </form>
              </ModalBody>
          </Modal>
      </div>
    );
  }

  render() {
    const columns = [
      {
        Header: "Nama Lengkap",
        accessor: "name",
        style: { textAlign: "center" },
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["name"] }),
        filterAll: true,
      },
      {
        Header: "Email",
        accessor: "email",
        style: { textAlign: "center" },
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["email"] }),
        filterAll: true,
      },
      {
        Header: "Telepon/WA",
        accessor: "phone",
        style: { textAlign: "center" },
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["phone"] }),
        filterAll: true,
      },
      {
        Header: "Sekolah",
        accessor: "sekolah.nama_sekolah",
        style: { textAlign: "center" },
        filterable: false,
      },
      {
        Header: "Keterangan",
        accessor: "keterangan",
        style: { textAlign: "center" },
        Cell: ({ value }) => {
          if (!value || typeof value !== "object") {
            return <div>{value}</div>; // Tampilkan langsung jika value adalah string

          }
          return (
            <div>
              <div>Kategori : {value.category || "N/A"}</div>
              <div>Deskripsi: {value.description || "N/A"}</div>
              <div>Kehadiran: {value.attendance || "N/A"}</div>
            </div>
          );
        },
        filterable: false,
      },
      {
        Header: "Attendance",
        accessor: "attandance",
        style: { textAlign: "center" },
        filterable: false,
        Cell: (row) => (
          <div>
            {
              row.original.attandance != null ? 
              <div>{Moment(row.original.updated_at).format("DD/MM/YYYY")} <br/> {Moment(row.original.updated_at).format('HH:mm')}</div> 
              :
              <button className='btn btn-success btn-sm btn-successs' onClick={() => this.handlePresent(row.original)}><i className='fa fa-check'></i></button>
            }
          </div>
        )
      },
    ];

    return (
      <Fragment>
        <div className="page-wrapper">
          <div className="container-fluid p-0">
            <div className="row m-1">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="text-left">
                          <h5 className="mb-1">
                            Total Registration :{" "}
                            <span className="badge badge-primary">
                              <Countup
                                end={this.state.registrasi_data.length}
                              />
                            </span>{" "}
                          </h5>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="text-right">
                          <select
                            className="form-control"
                            onChange={this.handleChange}
                            name="event_id"
                            value={this.state.event_id}
                          >
                            <option value="0"> --Filter Peserta-- </option>
                            {this.state.event_data.map((event) => {
                              return (
                                <option value={event.id} key={event.id}>
                                  {event.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-1">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <h5 className="mb-3">Data Register</h5>
                    <div className="row mb-3">
                      <div className="col-sm-6">
                        <ExportCSV
                          csvData={this.state.registrasi_data.map((item) => ({
                            ...item,
                            Kategori:
                              item.keterangan && item.keterangan.category
                                ? item.keterangan.category
                                : "",
                            Deskripsi:
                              item.keterangan && item.keterangan.description
                                ? item.keterangan.description
                                : "",
                            Kehadiran:
                              item.keterangan && item.keterangan.attendance
                                ? item.keterangan.attendance
                                : "",
                          }))}
                          fileName={"data-register-event-session"}
                        />
                      </div>
                      <div className="col-sm-6">
                        <Button className="pull-right" color="info" onClick={this.toggleModal}><i className="fa fa-qrcode"></i> Scan Attandance</Button>
                      </div>
                    </div>
                    <ReactTable
                      data={this.state.registrasi_data}
                      columns={columns}
                      defaultPageSize={10}
                      filterable
                      defaultFilterMethod={(filter, row) =>
                        String(row[filter.id]) === filter.value
                      }
                      className="-striped -highlight"
                      showPaginationBottom={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.renderScanAttandanceDialog()}
      </Fragment>
    );
  }
}

const reduxState = (state) => ({
  dataToken: state.Login.lgnInitialize,
  dataUser: state.Login.detailUser,
});

export default connect(reduxState, null)(RegisterEventPartner);
