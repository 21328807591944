import React, { Component } from "react"
import ReactTable from 'react-table';
import API_URL from "../../ApiUrl"
import axios from 'axios';
import Loader from "../../components/common/loader";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

class BannerApps extends Component {

    constructor(props) {
        super(props)
        this.state = {
            listData: [],
            id:'',
            banner:'',
            link_banner:'',
            link:'',
            name:'',
            status:'',
            type:'',
            textSave: 'Save',
            modalData:false,
            showLoading:false,
            btnDisabled: false
        }
    }

    componentDidMount(){
        this.loadData()
    }

    loadData = () => {
        axios.get(API_URL.superStudent+'/banner-app', {
            headers: { Authorization: `Bearer `+this.props.dataToken }
        })
        .then(response => {
            this.setState({
               listData: response.data.data
            })
        })
        .catch(error => console.log(error))
    }

    deletePost = (id) => {
        axios.delete(API_URL.superStudent+`/banner-app/${id}`, {
            headers: {
                Authorization: `Bearer `+this.props.dataToken
            }
        })
        .then(() => {
            toast.success("Data Berhasil di perbaharui")
            this.loadData()
        })
        .catch(error => console.log(error))
      }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleImage = (e) => {
        this.setState({
            banner: e.target.files[0]
        })
    }

    addRow = () => {
        this.setState({
            type:'add',
            banner:'',
            link:'',
            name:'',
            status:'',
            modalData:true
        })
    }

    editRow = (row) => {
        this.setState({
            type:'update',
            id:row.id,
            link_banner:row.banner,
            link:row.link,
            name:row.name,
            status:row.status,
            modalData:true
        })
    }

    saveRow = () => {
        this.setState({
            btnDisabled:true,
            textSave:'Saving...',
        })
        let formData = new FormData()
        formData.append('banner', this.state.banner)
        formData.append('link', this.state.link)
        formData.append('name', this.state.name)
        formData.append('status', this.state.status)
        formData.append('id', this.state.id)
        let url = '/banner-app';
        if (this.state.type === 'update') {
            formData.append('_method', 'PATCH');
            url = '/banner-app/'+this.state.id
        }

        axios.post(API_URL.superStudent+url,formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`, 
                'Content-Type': 'multipart/form-data' 
            }
        })
        .then(() => {
            this.setState({
                btnDisabled:false,
                textSave:'Save',
                modalData: false,
            })
            toast.success("Data Berhasil diperbaharui")
            this.loadData()
        })
        .catch(error => {
            this.setState({
                btnDisabled:false,
                textSave:'Save',
                modalData: false,
            })
            toast.error("Something Wrong")
        })
    }

    renderModal() {
        return (
            <div className="card-body btn-showcase">
              <Modal isOpen={this.state.modalData} toggle={() => this.setState({ modalData: false })}>
                <ModalHeader toggle={() => this.setState({ modalData: false })}>Update Data</ModalHeader>
                <ModalBody>
                <form >
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="form-group"> 
                                <label className='control-label'>Name<span className="text-danger">*</span></label>
                                <input className="form-control" value={this.state.name} onChange={this.handleInput} name="name" type="text" />
                            </div>
                            <div className="form-group"> 
                                <label className='control-label'>Link<span className="text-danger">*</span></label>
                                <input className="form-control" value={this.state.link} onChange={this.handleInput} name="link" type="text" />
                            </div>
                            <div className="form-group"> 
                                <label className='control-label'>Status<span className="text-danger">*</span></label>
                                <select className="form-control" name="status" value={this.state.status} onChange={this.handleInput}>
                                    <option value=''>--Select--</option>
                                    <option value='1'>Active</option>
                                    <option value='0'>Inactive</option>
                                </select>
                            </div>
                            <div className="form-group"> 
                                <label className='control-label'>Image<span className="text-danger">*</span></label>
                                <input type="file" className="form-control" onChange={this.handleImage} />
                                {this.state.type === 'update' ? <span className="text-danger">isi jika gambar akan diupdate</span> :''}
                            </div>
                            {
                                    this.state.type === 'update' ?
                                    <img className="img-fluid" alt="banner-event" src={this.state.link_banner} /> :''
                                }
                        </div>
                    </div>
                </form>
                </ModalBody>
                <ModalFooter>
                  <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveRow()}>{this.state.textSave}</Button>
                  <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.setState({ modalData: false })}>Cancel</Button>
                </ModalFooter>
              </Modal>
            </div>
          )
    }

    render() {
        const columns = [
            {
                Header: 'Banner',
                accessor: 'banner',
                style: {
                    textAlign: 'center'
                },
                Cell: (row) => (
                    <div>                     
                        <img style={{width: '100px'}} alt='gambar-event' src={row.original.banner}/>
                    </div>
                ),
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Link',
                accessor: 'link',
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell:(row) => (
                 
                        row.original.status === 1 ?
                        <label className='badge bg-success'>Active</label>
                        :
                        <label className='badge bg-danger'>Inactive</label>
                   
                ),
                style: {
                    textAlign: 'center'
                },
                width:100,
            },
            {
                Header: <b>#</b>,
                id: 'delete',
                width:100,
                accessor: str => "delete",
                Cell: (row) => (
                    <>
                     <button
                        onClick={()=> this.editRow(row.original)} 
                          className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil fa-2x"></i>
                        </button>
                        <button
                        onClick={()=> this.deletePost(row.original.id)} 
                          className="btn btn-outline-danger btn-xs mr-1" type="button" style={{border:'none'}} ><i className="fa fa-trash fa-2x"></i>
                        </button>
                    </>
                ),
                style: {textAlign: 'center'},
                filterable: false,
                sortable: false
            }
        ]
        return (
            <>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='mb-2'>
                                        <button onClick={this.addRow} className="btn btn-success" type="button" style={{border:'none'}} >Add Data </button>
                                    </div>
                                    <ReactTable
                                        data={this.state.listData}
                                        columns={columns}
                                        defaultPageSize={10}
                                        className="-striped -highlight"
                                    />
                                     <Loader showLoading={this.state.showLoading} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderModal()}
            </>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState, null)(BannerApps);