/* eslint-disable eqeqeq*/

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import axios from 'axios';
import API_URL from '../../../ApiUrl';

export class PlacementTestDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detailResult: [],
            user: "",
            writingResult: "",
            hash: this.props.match.params.hashid
        }
    }

    componentDidMount() {
        this.getDetailResult(this.state.hash)
    }

    getDetailResult(hashid){
        axios.get(API_URL.placementTest + 'result/' + hashid, { headers: { Authorization: `Bearer ${API_URL.placementTestToken}` }})
        .then(res=>{
            let data = res.data
            let writingResult = data.pop()
            this.setState({detailResult: data, user: data[0].tb_user, writingResult: writingResult})
        })
        .catch(err => console.log(err))
    }

    render() {
        return(
            <>
                <div className="p-5">
                    <h4>Name : {this.state.user.name}</h4>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Question</th>
                                    <th scope="col">User Answers</th>
                                    <th scope="col">Correct Answers</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.detailResult?.map((s)=>{
                                    return(
                                        <tr>
                                            <td><div dangerouslySetInnerHTML={{__html: s.tb_question.question}} /></td>
                                            <td>{(s.answer == 0 || s.answer === '') ? 'No Answer' : s.answer}</td>
                                            <td>{s.tb_question.opt_a}</td>
                                            <td>{s.answer === s.tb_question.opt_a ? (<i className='fa fa-check text-success'></i>) : (<i className='fa fa-times text-danger'></i>)}</td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState)(withRouter(PlacementTestDetail));