import React, { Fragment } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Switch, Route, Redirect } from 'react-router-dom';
import { NavTab, RoutedTabs } from 'react-router-tabs';
import { List, Activity, FileText, Image } from 'react-feather';

const SocialMedia = () => {
    return (
        <Fragment>
            <div className="page-wrapper">
                {/* <!-- Page Body Start--> */}
                <div className="container-fluid p-0">
                    <Breadcrumb title="Social Media & Design" parent="Social Media" />
                    <div className="container-fluid">
                        <div className="row theme-tab">
                            <div className="col-sm-12">
                                <RoutedTabs>
                                    <div className="tabs tab-title">
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/social-media-design/report-sosmed`}>
                                            <Activity /> Report SosMed
                                        </NavTab>
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/social-media-design/post-sosmed`}>
                                            <List /> Post SosMed
                                        </NavTab>
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/social-media-design/key-opinion-leader`} >
                                            <FileText/> Key Opinion Leader
                                        </NavTab>
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/social-media-design/banner-apps`} >
                                            <Image/> Baner Apps
                                        </NavTab>
                                    </div>
                                </RoutedTabs>
                                <Switch>
                                    <Route
                                        exact
                                        path={`${process.env.PUBLIC_URL}/pages/social-media-design`}
                                        render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/social-media-design/report-sosmed`} />}
                                    />
                                    <Route path={`${process.env.PUBLIC_URL}/pages/social-media-design/list-register`} />
                                    <Route path={`${process.env.PUBLIC_URL}/pages/social-media-design/task-list`} />
                                    <Route path={`${process.env.PUBLIC_URL}/pages/social-media-design/report-sosmed`} />
                                    <Route path={`${process.env.PUBLIC_URL}/pages/social-media-design/key-opinion-leader`} />
                                    <Route path={`${process.env.PUBLIC_URL}/pages/social-media-design/banner-apps`} />
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SocialMedia;