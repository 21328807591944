
/* eslint-disable react-hooks/exhaustive-deps, eqeqeq */
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import API_URL from '../../../ApiUrl'
import axios from 'axios';
import { Chart } from "react-google-charts"
import CountUp from 'react-countup';

const PeformanceBranch = () => {

    const[dataGraphic,setDataGraphic] = useState({ grafik: [], total:0})
    const[dataType,setDataType] = useState(0)
    const[master,setMaster] = useState([]);

    useEffect(() => {
        loadDataIndikator()
    },[])

    useEffect(() => {
        loadDataGraphic()
    },[dataType])

    const { dataToken } = useSelector(state => ({
        dataToken:state.Login.lgnInitialize
    }))

    const loadDataIndikator = () => {
        let formData = new FormData()
        formData.append('code_jobdesk','JD016')
        formData.append('all-indikator','0')
        axios.post(API_URL.superStudent+'/kpi/list-indikator', formData,{
            headers: { 'Authorization': `Bearer ${dataToken}` }
        })
        .then(response => {
            setMaster(response.data.data)
        })
        .catch(error => console.log(error))
    }

    const loadDataGraphic = () => {
        let formData = new FormData()
        formData.append('type', dataType)
        axios.post(API_URL.superStudent+'/branch-peformance/recap-kpi-all', formData, {
            headers: { 'Authorization': `Bearer ${dataToken}` }
        })
        .then(response => {
            setDataGraphic(response.data.data)
        })
        .catch(error => console.log(error))
    }

    return(
        <>
            <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="col">
                            <h6 className="f-w-600">Yearly Peformance Branch</h6>
                        </div>
                        <div className="col">
                            <select name='index' value={dataType} onChange={e => setDataType(e.target.value)} className='form-control'>
                            {
                                master.map((indikator, index) => (
                                <option key={indikator.id} value={index}>{indikator.name}</option>
                                ))
                            }
                            </select>
                        </div>
                    </div>
             
                </div>
                <div className="card-body">
                <h4 className="font-primary text-center mb-0">
                {
                    dataType == 4 ?
                    <CountUp separator="." decimal="," prefix="Rp " decimals={2} className="counter" end={dataGraphic.total} />
                    :
                    <CountUp separator="." className="counter" end={dataGraphic.total} />
                }
                </h4>
                    <Chart
                        options={{
                            legend: { position: 'top' },
                            vAxis:{
                                format:'decimal'
                            }
                        }}
                        width={'100%'}
                        height={'300px'}
                        chartType="Bar"
                        loader={<div>Loading Chart</div>}
                        data={dataGraphic.grafik}
                    />
                </div>
            </div>
        </>
    );
}

export default PeformanceBranch;