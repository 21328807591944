import React, { useState, useEffect, useCallback } from "react";
import API_URL from "../../../ApiUrl";
import axios from 'axios';
import { useSelector } from 'react-redux';

const ReportTeacherAttendance = ({startDate, endDate}) => {
    const [dataTeacher, setDataTeacher] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [selectedTeacher, setSelectedTeacher] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [currentDateRange, setCurrentDateRange] = useState({ start: '', end: '' });

    const { dataToken } = useSelector(state => ({
        dataToken: state.Login.lgnInitialize
    }));

    const loadReportTeacherAttandance = useCallback(() => {
        axios.post(
            API_URL.superStudent + '/english-course/report-teacher-attandance', 
            {
                start_date: startDate,
                end_date: endDate
            },
            {
                headers: { 'Authorization': `Bearer ${dataToken}` }
            }
        )
        .then(response => {
            const teacherData = response.data.data;
            setDataTeacher(teacherData);
            setCurrentDateRange({ start: startDate, end: endDate });

            if (teacherData.length > 0) {
                const extractedPrograms = Object.keys(teacherData[0])
                    .filter(key => 
                        key !== 'nama' && 
                        typeof teacherData[0][key] === 'string' && 
                        key !== 'detail'
                    );
                setPrograms(extractedPrograms);
            }
        })
        .catch(error => {
            console.error('Error fetching teacher attendance:', error);
        });
    }, [startDate, endDate, dataToken]);

    const loadTeacherDetails = (teacherName) => {
        axios.post(
            API_URL.superStudent + '/english-course/detail-report-teacher-attandance',
            {
                nama: teacherName,
                start_date: startDate,
                end_date: endDate
            },
            {
                headers: { 'Authorization': `Bearer ${dataToken}` }
            }
        )
        .then(response => {
            setSelectedTeacher({
                nama: teacherName,
                details: response.data.data
            });
            setShowModal(true);
        })
        .catch(error => {
            console.error('Error fetching teacher details:', error);
        });
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedTeacher(null);
    };

    useEffect(() => {
        if (startDate && endDate) {
            loadReportTeacherAttandance();
        }
    }, [startDate, endDate, loadReportTeacherAttandance]);

    return (
        <div style={{width: '100%', overflowX: 'auto'}}>
            <table style={{width: '100%', borderCollapse: 'collapse'}}>
                <thead>
                    <tr style={{backgroundColor: '#f2f2f2'}}>
                        <th style={{border: '1px solid #ddd', padding: '8px'}}>No</th>
                        <th style={{border: '1px solid #ddd', padding: '8px', textAlign:'center'}}>Nama Guru</th>
                        {programs.map((program) => (
                            <th 
                                key={program} 
                                style={{border: '1px solid #ddd', padding: '8px', textAlign: 'center'}}
                            >
                                {program}
                            </th>
                        ))}
                        <th style={{border: '1px solid #ddd', padding: '8px'}}>Detail</th>
                    </tr>
                </thead>
                <tbody>
                    {dataTeacher.map((teacher, index) => (
                        <tr key={teacher.nama}>
                            <td style={{border: '1px solid #ddd', padding: '8px', textAlign: 'center'}}>
                                {index + 1}
                            </td>
                            <td style={{border: '1px solid #ddd', padding: '8px'}}>
                                {teacher.nama}
                            </td>
                            {programs.map((program) => (
                                <td 
                                    key={program} 
                                    style={{border: '1px solid #ddd', padding: '8px', textAlign: 'center'}}
                                >
                                    {teacher[program]}
                                </td>
                            ))}
                            <td style={{border: '1px solid #ddd', padding: '8px', textAlign: 'center'}}>
                                <button 
                                    onClick={() => loadTeacherDetails(teacher.nama)}
                                    style={{
                                        backgroundColor: '#f0f0f0', 
                                        border: '1px solid #ddd', 
                                        padding: '5px 10px', 
                                        cursor: 'pointer'
                                    }}
                                >
                                    View
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {showModal && selectedTeacher && (
    <div 
        style={{ 
            display: 'block', 
            backgroundColor: 'rgba(0,0,0,0.5)',
            overflow: 'auto',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1050
        }} 
        onClick={(e) => {
            if (e.target === e.currentTarget) {
                closeModal();
            }
        }}
    >
        <div style={{ margin: '1.75rem auto', maxWidth: '800px' }}>
            <div style={{ 
                backgroundColor: 'white', 
                borderRadius: '5px', 
                maxHeight: '90vh',
                overflow: 'hidden'
            }}>
                <div style={{ 
                    padding: '15px', 
                    borderBottom: '1px solid #ddd', 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <h5>
                        Detail for Teacher: {selectedTeacher.nama}
                        <small style={{ 
                            marginLeft: '10px', 
                            color: '#6c757d' 
                        }}>
                            ({currentDateRange.start} to {currentDateRange.end})
                        </small>
                    </h5>
                    <button 
                        onClick={closeModal}
                        style={{ 
                            backgroundColor: '#dc3545', 
                            color: '#fff', 
                            border: 'none',
                            width: '35px',
                            height: '35px',
                            fontSize: '20px',
                            cursor: 'pointer', 
                            borderRadius: '5px' 
                        }}
                    >
                        &times;
                    </button>
                </div>
                <div style={{ 
                    padding: '15px', 
                    maxHeight: 'calc(90vh - 120px)', 
                    overflowY: 'auto' 
                }}>
                    <table style={{width: '100%', borderCollapse: 'collapse'}}>
                        <thead>
                            <tr style={{backgroundColor: '#f2f2f2'}}>
                                <th style={{border: '1px solid #ddd', padding: '8px'}}>No</th>
                                <th style={{border: '1px solid #ddd', padding: '8px'}}>Student Name</th>
                                <th style={{border: '1px solid #ddd', padding: '8px'}}>Program</th>
                                <th style={{border: '1px solid #ddd', padding: '8px'}}>Durasi</th>
                                <th style={{border: '1px solid #ddd', padding: '8px'}}>Kehadiran</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedTeacher.details.map((detail, index) => {
                                const formattedDate = new Date(detail.date_time).toLocaleDateString('id-ID', {
                                    day: '2-digit',
                                    month: 'short',
                                    year: 'numeric',
                                });

                                const totalForProgram = detail[detail.nama_program] || 0;
                                
                                return (
                                    <tr key={index}>
                                        <td style={{border: '1px solid #ddd', padding: '8px', textAlign: 'center'}}>{index + 1}</td>
                                        <td style={{border: '1px solid #ddd', padding: '8px'}}>{detail.nama_lengkap}</td>
                                        <td style={{border: '1px solid #ddd', padding: '8px'}}>{detail.nama_program}</td>
                                        <td style={{border: '1px solid #ddd', padding: '8px', textAlign: 'center'}}>
                                            {totalForProgram} jam
                                        </td>
                                        <td style={{border: '1px solid #ddd', padding: '8px'}}>{formattedDate}</td>
                                    </tr>
                                );
                            })}
                            {selectedTeacher.details.length === 0 && (
                                <tr>
                                    <td colSpan="5" style={{textAlign: 'center', padding: '15px'}}>
                                        Tidak ada data kehadiran dalam rentang tanggal ini
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div style={{ 
                    padding: '15px', 
                    borderTop: '1px solid #ddd', 
                    textAlign: 'right' 
                }}>
                    <button 
                        onClick={closeModal}
                        style={{ 
                            backgroundColor: '#6c757d', 
                            color: 'white', 
                            border: 'none', 
                            padding: '8px 16px', 
                            borderRadius: '5px', 
                            cursor: 'pointer' 
                        }}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
)}
        </div>
    );
};

export default ReportTeacherAttendance;