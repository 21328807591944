/* eslint-disable no-useless-concat */
/* eslint-disable eqeqeq */

import React, { Fragment, Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withRouter } from "react-router";
import Loader from '../../components/common/loader';
import Moment from 'moment';
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { matchSorter } from 'match-sorter';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import { saveAs } from "file-saver";
import JSZip from "jszip";
import Countup from "react-countup";
import * as XLSX from 'xlsx';

class StudyTourList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            modal: false,
            detail: '',
            dataIntership: [],
            status: 1,
            btnDisabled: false,
            modalPreview: false,
            // Set tanggal awal dan akhir menggunakan format YYYY-MM-DD untuk input type="date"
            startDate: '', // Akan di-set setelah data termuat
            endDate: Moment().format('YYYY-MM-DD'),
        }
    }

    componentDidMount() {
        // Load semua data awal tanpa filter tanggal
        this.getAllDataIntership();
    }

    // Mengambil semua data internship tanpa filter tanggal
    getAllDataIntership() {
        this.setState({ showLoading: true });
        
        const url = `${API_URL.superStudent}/intership`;
        
        axios.get(url, {
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}`,
            },
        })
        .then(response => {
            const data = response.data.data;
            
            // Cari tanggal paling awal pada data
            let earliestDate = Moment().format('YYYY-MM-DD'); // Default ke hari ini
            
            if (data && data.length > 0) {
                data.forEach(item => {
                    if (item.date_created) {
                        const itemDate = Moment(item.date_created).format('YYYY-MM-DD');
                        if (itemDate < earliestDate || earliestDate === Moment().format('YYYY-MM-DD')) {
                            earliestDate = itemDate;
                        }
                    }
                });
            }
            
            this.setState({
                showLoading: false,
                dataIntership: data,
                startDate: earliestDate
            });
        })
        .catch(error => {
            console.error('Error fetching data:', error);
            this.setState({ showLoading: false });
        });
    }

    // Method untuk mendapatkan data dengan filter tanggal
    getDataIntership(formData = null) {
        this.setState({ showLoading: true });
    
        let url = `${API_URL.superStudent}/intership`;
        const params = formData ? new URLSearchParams(Object.fromEntries(formData)).toString() : '';
    
        if (params) {
            url += `?${params}`;
        }
    
        axios.get(url, {
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}`,
            },
        })
        .then(response => {
            this.setState({
                showLoading: false,
                dataIntership: response.data.data,
            });
        })
        .catch(error => {
            console.error('Error fetching data:', error);
            this.setState({ showLoading: false });
        });
    }
    
    handleDateChange = (e, dateType) => {
        const selectedDate = e.target.value; // Format YYYY-MM-DD
        
        this.setState({
            [dateType]: selectedDate
        }, () => {
            // Terapkan filter jika kedua tanggal telah di-set
            if (this.state.startDate && this.state.endDate) {
                const formData = new FormData();
                formData.append('start_date', this.state.startDate);
                formData.append('end_date', this.state.endDate);
                this.getDataIntership(formData);
            }
        });
    }

    applyDateFilter = () => {
        if (this.state.startDate && this.state.endDate) {
            const formData = new FormData();
            formData.append('start_date', this.state.startDate);
            formData.append('end_date', this.state.endDate);
            this.getDataIntership(formData);
        }
    }

    handleInput(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    filter = () => {
        var formData = new FormData();
        formData.append('start_date', this.state.startDate)
        formData.append('end_date', this.state.endDate)
        this.getDataIntership(formData)
    }

    editRow(row) {
        this.setState({
            modal: true,
            detail: row,
            status: 1
        })
    }

    async downloadRow(row) {
        const zipUrl = row.document

        try {
            const response = await fetch(zipUrl);
            if (!response.ok) throw new Error("Gagal mengunduh file ZIP");
      
            const blob = await response.blob();
            const zip = await JSZip.loadAsync(blob);
      
            zip.forEach(async (relativePath, file) => {
                const content = await file.async("blob");
                saveAs(content, file.name);
            });
      
            alert("File berhasil diekstrak dan diunduh!");
        } catch (error) {
            console.error("Error:", error);
            alert("Terjadi kesalahan saat mengunduh atau mengekstrak ZIP");
        }
    }

    handleModalDownload = () => {
        const rows = [];
        if (!this.state.dataIntership || !Array.isArray(this.state.dataIntership)) {
            console.error("Data tidak valid:", this.state.dataIntership);
            return;
        }
    
        const formatDate = (dateString) => {
            if (!dateString) return "Tidak diketahui";
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            try {
                const date = new Date(dateString);
                const day = date.getDate();
                const month = months[date.getMonth()];
                const year = date.getFullYear();
                return `${day} ${month} ${year}`;
            } catch (error) {
                console.error("Format tanggal tidak valid:", dateString);
                return "Tidak diketahui";
            }
        };
    
        this.state.dataIntership.forEach((item) => {
            if (!item) {
                console.error("Item kosong:", item);
                return;
            }
    
            const posisiMagang =
                item.posisi_magang === 1
                    ? "Culinary"
                    : item.posisi_magang === 2
                    ? "F&B Rotation"
                    : "Room Division";
    
            rows.push({
                Nama: item.nama_lengkap || "Tidak diketahui",
                Email: item.email || "Tidak diketahui",
                No_Whatsapp: item.no_wa || "Tidak diketahui",
                Propinsi: item.provinsi?.nama_propinsi || "Tidak diketahui",
                Recomender: item.consultant?.nama || "Tidak diketahui",
                Sekolah: item.sekolah?.nama_sekolah || "Tidak diketahui",
                Magang: posisiMagang,
                TanggalDaftar: formatDate(item.date_created),
                Document: item.document,
            });
        });
    
        if (rows.length === 0) {
            console.warn("Tidak ada data yang valid untuk diekspor.");
            return;
        }
    
        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
    
        XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
        XLSX.writeFile(workbook, "data-internship.xlsx");
    };
    
    closeDialog() {
        this.setState({
            modal: false
        })
    }

    closePreview() {
        this.setState({
            modalPreview: false
        })
    }

    saveRow() {
        this.setState({
            btnDisabled: true
        })

        var formData = new FormData();
        formData.append('status', this.state.status);
        formData.append('_method', 'PATCH');

        const url = API_URL.superStudent+'/intership/list/'+this.state.detail.kode_register;
        axios.post(url, formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`,
                'Content-Type': 'multipart/form-data'
            } 
        })
        .then(response => response.data)
        .then((data) => {
            this.setState({ modal:false, btnDisabled: false });
            this.getAllDataIntership();
            toast.success("Update Successfully");
        });
    }

    downloadFile = (e, url, filename) => {
        saveAs(url, filename);
    }

    renderDialog() {
        const { modal } = this.state;

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>Update Status</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Status</label>
                                    <select className="form-control" name="status" id="status" onChange={(e) => this.handleInput(e)}>
                                        <option value={1}>Follow Up</option>
                                        <option value={2}>Progress</option>
                                        <option value={3}>Document</option>
                                        <option value={4}>Cancel</option>
                                    </select>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.saveRow()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    renderPreview() {
        const { modalPreview, detail, title, urlPreview } = this.state;
        
        return (
            <Modal className="modal-dialog" isOpen={modalPreview} toggle={() => this.closePreview()} size="lg">
                <ModalHeader toggle={() => this.closePreview()}>{title}</ModalHeader>
                <ModalBody style={{maxHeight: 'calc(100vh - 210px)', overflowY: 'auto'}}>
                    <div className='mt-3'>
                        {detail && detail.document && (
                            <>
                                <h6><strong><li>{detail.document.split('/').pop()}</li></strong></h6>
                                {
                                    detail.document.split('.').pop().toLowerCase() === 'png' || 
                                    detail.document.split('.').pop().toLowerCase() === 'jpg' || 
                                    detail.document.split('.').pop().toLowerCase() === 'jpeg' ?
                                    <img className='image' src={urlPreview} alt="" /> 
                                    :
                                    <DocViewer 
                                        pluginRenderers={DocViewerRenderers} 
                                        documents={[{ uri: detail.document }]}
                                        config={{
                                            header: {
                                                disableHeader: true,
                                                disableFileName: false,
                                                retainURLParams: false
                                            }
                                        }}
                                    />
                                }
                                <div className="form-row">
                                    <div className="col-md-6 mt-2" style={{textAlign: "end"}}>
                                        <button 
                                            onClick={(e) => this.downloadFile(e, detail.document, detail.document)} 
                                            className="btn btn-outline-success mr-1" 
                                            type="button"
                                        >
                                            <i className="fa fa-download"></i>
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    render() {
        const dataCount = this.state.dataIntership.length;
        const columns = [
            {
                Header: 'Nama',
                accessor: 'nama_lengkap',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["nama_lengkap"] }),
                filterAll: true
            },
            {
                Header: 'Email',
                accessor: 'email',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["email"] }),
                filterAll: true
            },
            {
                Header: 'No. Whatsapp',
                accessor: 'no_wa',
                Cell: (row) => ( 
                    row.original.no_wa.substring(0,2) === '62' ?
                    <a href={'https://wa.me/'+row.original.no_wa} rel='noopener noreferrer' target='_blank'>{row.original.no_wa}</a>
                    :
                    <a href={'https://wa.me/'+'62'+row.original.no_wa.substring(1)} rel='noopener noreferrer' target='_blank'>{row.original.no_wa}</a>
                ),
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["no_wa"] }),
                filterAll: true
            },
            {
                Header: 'Propinsi',
                accessor: 'provinsi.nama_propinsi',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["provinsi.nama_propinsi"] }),
                filterAll: true
            },
            {
                Header: 'Recomender',
                accessor: 'consultant.nama',
                filterable: false
            },
            {
                Header: 'Sekolah / Kampus',
                accessor: 'sekolah.nama_sekolah',
                filterable: false
            },
            {
                Header: 'Posisi Magang',
                accessor: 'posisi_magang',
                filterable: false,
                Cell: (row) => (
                    <div>
                        {
                            row.original.posisi_magang == 1 ?
                            'Culinary'
                            :
                            row.original.posisi_magang == 2 ?
                            'F&B Rotation'
                            :
                            'Room Division'
                        }
                    </div>
                ),
            },
            {
                Header: 'Link',
                accessor: 'link',
                filterable: false,
                Cell: (row) => (
                    <div>
                        <a href={row.original.link} target='_blank' rel="noopener noreferrer">{row.original.link}</a>
                    </div>
                ),
            },
            {
                Header: 'Tanggal Daftar',
                accessor: 'date_created',
                filterable: false,
                Cell: (row) => (
                    <div>
                        {Moment(row.original.date_created).format('DD MMMM YYYY')}
                    </div>
                ),
            },
            {
                Header: 'Status',
                accessor: 'status',
                filterable: false,
                Cell: (row) => (
                    <div style={{textAlign:'center'}}>
                        {
                            row.original.status === 1 ? 
                            <span className={'badge bg-secondary'}>Follow Up</span>
                            :
                            row.original.status === 2 ? 
                            <span className={'badge bg-info'}>Progress</span>
                            :
                            row.original.status === 3 ? 
                            <span className={'badge bg-success'}>Document</span>
                            :
                            row.original.status === 4 ? 
                            <span className={'badge bg-danger'}>Cancel</span>
                            :
                            <span className={'badge bg-primary'}>New</span>
                        }
                    </div>
                )
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div>                     
                        <button onClick={() => this.downloadRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} >
                            <i className="fa fa-file" style={{ width: 35, fontSize: 16, padding: 11, color: 'blue' }}></i>{row.id}
                        </button>
                        <button onClick={() => this.editRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} >
                            <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i>{row.id}
                        </button>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ];

        return (
            <Fragment>
                <Breadcrumb title="Internship" />
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <h5 className="mb-0">Total Registration <span className="badge badge-info"> <Countup end={dataCount}/> </span></h5>
                                            </div>
                                        </div>
                                        <div className="row my-3">
                                            <div className="col-md-6">
                                                <div className="input-group">
                                                    {/* Input tanggal langsung tanpa perlu trigger klik secara programatik */}
                                                    <input 
                                                        id="startDatePicker"
                                                        type="date"
                                                        className="form-control"
                                                        value={this.state.startDate}
                                                        onChange={(e) => this.handleDateChange(e, 'startDate')}
                                                    />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">-</span>
                                                    </div>
                                                    <input 
                                                        id="endDatePicker"
                                                        type="date"
                                                        className="form-control"
                                                        value={this.state.endDate}
                                                        onChange={(e) => this.handleDateChange(e, 'endDate')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 text-end">
                                                <a href="https://prioritas.niecindonesia.com/" rel="noopener noreferrer" target="_blank">
                                                    <button className="btn btn-primary">Landing Page</button>
                                                </a>
                                            </div>
                                            <div className="col-md-3 text-end">
                                                <button onClick={() => this.handleModalDownload()} className="btn btn-success">
                                                    Download Excel
                                                </button>
                                            </div>
                                        </div>

                                        <br/>
                                        {
                                            this.state.dataIntership.length > 0 ?
                                            <ReactTable
                                                data={this.state.dataIntership}
                                                columns={columns}
                                                filterable
                                                defaultFilterMethod={(filter, row) =>
                                                    String(row[filter.id]) === filter.value}
                                                defaultPageSize={10}
                                                className="-striped -highlight"
                                            /> 
                                            : 
                                            <Loader showLoading={this.state.showLoading} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderDialog()}
                {this.renderPreview()}
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
});

export default connect(reduxState)(withRouter(StudyTourList));
