import React, { Fragment } from 'react';
import { Breadcrumb } from 'reactstrap';
import DataTableTalentMappingMuri from './datatableTalentMappingMuri';

const TalentMappingMuri = () => {
    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    <Breadcrumb title='Talent Mapping Muri' parent="Talent Mapping Muri">
                        <div className="row m-1">
                            <div className="col-md-12">
                                <h4 className='my-2'><strong>TALENT MAPPING MURI</strong></h4>
                            </div>
                        </div>
                        <div className="row bg-white py-4">
                            <div>
                                <DataTableTalentMappingMuri />
                            </div>
                        </div>
                    </Breadcrumb>
                </div>
            </div>
        </Fragment>
    )
};

export default TalentMappingMuri;