import React, { Component } from 'react';
import Moment from 'moment';
import axios from 'axios'
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import setRangeDate from '../../../helper/general-function';
import API_URL from '../../../ApiUrl';
import EnglishCoursePromo from './english-course-promo';
import ReportProgramType from './report-program-type';
import ReportTeacherAttendance from './report-teacher-attandance';

class EnglishCourseHome extends Component {

    constructor(props) {
        super(props)
        let data = setRangeDate('this_year')
        this.state = {
            date: 'dinammic_date',
            startDate: data.start,
            endDate: data.end,
            dataIncome: {
                columns: [],
                rows: []
            },
            staticFilter: 'none',
            dinamicFilter: ''
        }
    }

    componentDidMount() {
        this.loadDataIncome()
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    loadDataIncome() {
        const url = API_URL.superStudentV2 + '/consultant-peformance/income-by-type';
        let formData = new FormData();
        formData.append('start_date', this.state.startDate)
        formData.append('end_date', this.state.endDate)
        formData.append('code_jenis_pembayaran', 'JPB012')
        axios.post(url, formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        }).then(response => response.data.data).then((data) => {
            this.setState({ dataIncome: data })
        });
    }

    handlePickerStartMonth = (e) => {
        this.setState({ startDate: Moment(e).startOf('month').format('YYYY-MM-DD') }, () => this.loadDataIncome())
    }

    handlePickerEndMonth = (e) => {
        this.setState({ endDate: Moment(e).endOf('month').format('YYYY-MM-DD') }, () => this.loadDataIncome())
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            if (this.state.date === 'dinammic_date') {
                this.setState({
                    dinamicFilter: '',
                    staticFilter: 'none'
                })
            } else {
                this.setRangeDate()
            }
        })
    }

    setRangeDate = () => {
        const data = setRangeDate(this.state.date);
        this.setState({
            startDate: data.start,
            endDate: data.end,
            dinamicFilter: 'none',
            staticFilter: ''
        }, () => this.loadDataIncome());
    }

    filter = () => {
        this.setState({
            date: 'dinammic_date',
            dinamicFilter: '',
            staticFilter: 'none',
            startDate: this.state.startDate,
            endDate: this.state.endDate,
        }, () => this.loadDataIncome());
    }

    handleDateChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: Moment(value).format('YYYY-MM-DD')
        });
    }

    render() {
        return (
            <>
                <div className="tab-content active default" id="tab-1">
                    <div className="row m-1">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row'>
                                        <div className='col-md-4 col-sm-6'>
                                            <h5>Report Program By Type</h5>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                    <div className='col-md-4 col-sm-12'>
                                            <select 
                                                name='date' 
                                                value={this.state.date} 
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    this.setState({ date: selectedValue }, () => {
                                                        if (selectedValue === 'dinammic_date') {
                                                            this.setState({
                                                                dinamicFilter: '',
                                                                staticFilter: 'none'
                                                            });
                                                        } else {
                                                            this.setRangeDate();
                                                        }
                                                    });
                                                }} 
                                                className='form-control'
                                            >
                                                <option value='this_week'>This Week</option>
                                                <option value='last_week'>Last Week</option>
                                                <option value='this_month'>This Month</option>
                                                <option value='last_month'>Last Month</option>
                                                <option value='this_year'>This Year</option>
                                                <option value='last_year'>Last Year</option>
                                                <optgroup label='Quartal'>
                                                    <option value='1'>Quartal I</option>
                                                    <option value='2'>Quartal II</option>
                                                    <option value='3'>Quartal III</option>
                                                    <option value='4'>Quartal IV</option>
                                                </optgroup>
                                                <option value='dinammic_date'>Custom Date</option>
                                            </select>
                                        </div>
                                        <div className='col-md-8 col-sm-12'>
                                            <div style={{ display: this.state.dinamicFilter }} className='row'>
                                                <div className="col-md-4 mb-2">
                                                    <input 
                                                        className="form-control" 
                                                        type="date" 
                                                        onChange={this.handleDateChange} 
                                                        name="startDate" 
                                                        value={this.state.startDate} 
                                                    />
                                                </div>
                                                <div className="col-md-4 mb-2">
                                                    <input 
                                                        className="form-control" 
                                                        type="date" 
                                                        onChange={this.handleDateChange} 
                                                        name="endDate" 
                                                        value={this.state.endDate} 
                                                    />
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className='mb-5'>
                                        <ReportProgramType date={this.state.date} startDate={this.state.startDate} endDate={this.state.endDate} />
                                    </div>
                                    <div className='mb-5'>
                                        <ReportTeacherAttendance date={this.state.date} startDate={this.state.startDate} endDate={this.state.endDate} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row m-1">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row'>
                                        <div className='col-md-4 col-sm-6'>
                                            <h5>Income English Course</h5>
                                        </div>
                                        <div className='col-md-6 col-sm-12'>
                                            <div className='form-group'>
                                                <div className='input-group'>
                                                    <DatePicker dateFormat='MMMM yyyy' dateFormatCalendar='MMM' selected={Moment(this.state.startDate)._d} showMonthYearPicker className='form-control' onChange={this.handlePickerStartMonth} />
                                                    <DatePicker dateFormat='MMMM yyyy' selected={Moment(this.state.endDate)._d} showMonthYearPicker className='form-control' onChange={this.handlePickerEndMonth} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className='table-responsive'>
                                        <table style={{ fontSize: '10px' }} className='table table-striped table-hover table-bordered'>
                                            <thead className='tbl-strip-thad-bdr'>
                                                <tr className='table-secondary'>
                                                    <th scope="col">#</th>
                                                    {
                                                        this.state.dataIncome.columns.length > 0 ?
                                                            this.state.dataIncome.columns.map((item, index) => (
                                                                <th key={'columns' + index}>{item}</th>
                                                            ))
                                                            : ''
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.dataIncome.rows.length > 0 ?
                                                        this.state.dataIncome.rows.map((item, index) => (
                                                            <tr style={{ overflow: 'scroll' }} key={'rows' + index}>
                                                                <th>{index + 1}</th>
                                                                {
                                                                    item.map((cell, index) => (
                                                                        <th key={'cell' + index}>{cell}</th>
                                                                    ))
                                                                }
                                                            </tr>
                                                        ))
                                                        : ''
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <EnglishCoursePromo />
                </div>
            </>
        );
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState, null)(EnglishCourseHome);