/* eslint-disable react-hooks/exhaustive-deps */

import React, {useState, useEffect } from "react";
import API_URL from "../../../ApiUrl";
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux'
import setRangeDate from '../../../helper/general-function';

const ReportProgramType = ({date, startDate, endDate}) => {

    const[dataProgram,setDataProgram] = useState([{source:[], consultants:[]}]);
    const { dataToken } = useSelector(state => ({
        dataToken:state.Login.lgnInitialize
    }))

    const loadReportProgram = async () => {
        try {
          const url = `${API_URL.superStudent}/english-course/report-program-type`;
          const formData = new FormData();
          
          if (date !== 'dinammic_date') {
            const dateRange = setRangeDate(date);
            formData.append('start_date', dateRange.start);
            formData.append('end_date', dateRange.end);
          } else {
            formData.append('start_date', startDate);
            formData.append('end_date', endDate);
          }
      
          const response = await axios.post(url, formData, {
            headers: { 'Authorization': `Bearer ${dataToken}` }
          });
          setDataProgram(response.data.data);
        } catch (error) {
          console.error('Error loading report program:', error);
        }
      };
      

    useEffect(() => {
        loadReportProgram();
    }, [date, startDate, endDate, dataToken]);

    return(
        <>
            <Bar data={{
                labels: dataProgram.program,
                datasets: dataProgram.source,
                        }} options={{
                maintainAspectRatio: true,
                legend: {
                    display: true,
                },
                scales: {
                    xAxes: [{
                        stacked: true,
                    }],
                yAxes: [{
                    stacked: true,
                }]
                },
         }}  />
        </>
    );

}

export default ReportProgramType;